import { useState, ChangeEvent, FormEvent, useEffect} from 'react'
import axios from 'axios'
import { APP_NAME, FrontUrl, ImagesUrl, ServerUrl, Token, config, styles } from '../components/includes';
import Cookies from 'js-cookie'
import { ErrorModal, LoadingModal, SuccessModal, closeModal } from '../components/notify';
import Footer from '../components/Footer';

import Select, { components } from 'react-select'
import { Helmet } from 'react-helmet';
const AddUser =()=> {

  const [settings, setSettings] = useState([]as any)

	const [clientCode, setClientCode] = useState('ek'+Math.random().toString(36).substring(2,9))

let expire = new Date(new Date().setFullYear(new Date().getFullYear() + 2)).toISOString().slice(0,10);


let Initial = {

  prefix:'',	
  company:'',	
  role:'',	
  job_title:'',	
  country:'Nigeria',	
  region:'',	
  city:'city',	
  street:'street',	
  address:'',	
  more_info:'',	
  about:'',	
  photo:'',	
  firstname:'',	
  middlename:'',
  lastname:'',	
  username:'',	
  position:'',	
  password:'',
  confirm_password:'',	
  email_address:'',	
  expiry_date:expire,	
  allowExchange:'Yes',	
  staff_create:'self',
  is_hidden:true,
  referer:'' as any,
  referer_no:''
}
  const [client, setClient] = useState(Initial);

  const [errors, setErrors] = useState({
    referer:'',
    country:'',
    firstname:'',
    lastname:'',
    username:'',
    email_address:'',
    prefix:'',
    allowExchange:'',
    password:'',
    referer_no:'',
    successMessage:'',
    errorMessage:''
  });

  
const handleReset =()=>{
  setClient(Initial)
}

  const handleSelect = (option:any, action:any)=>{
    setClient({...client, [action.name]:option.value});
      setErrors({...errors, [action.name]:''})
       }

       const handleChange =(event:ChangeEvent<HTMLInputElement>)=>{
        let {name, value} = event.target;	
        if(name==='username'){
          value = value.replace(/,?[ ]/g, '-')
        }
        setClient({...client, [name]:value});
       setErrors({...errors, [name]:''});
     }

    


     const handleSubmit =(event: FormEvent<HTMLButtonElement>)=>{
      event.preventDefault();
  
      let error = {...errors}; 
      let formIsValid = true;
  
  let msg ='This field is required';
  
  if(!client.firstname){
      error.firstname =msg;
      formIsValid = false;
  } 


  if(!client.lastname){
    error.lastname =msg;
    formIsValid = false;
} 

  let email = client.email_address.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i); 
if(!email){
  error.email_address =msg;
  formIsValid = false;
} 

/* 
if(client.prefix.length===0){
  error.prefix=msg;
  formIsValid = false;
} */



if(!client.username){
error.username =msg;
formIsValid = false;
}


  if(!client.password){
    error.password =msg;
    formIsValid = false;
}
  

 
if(!client.referer){
  error.referer =msg;
  formIsValid = false;
}


if(client.referer==='Representative'){

if(!client.referer_no){
    error.referer_no =msg;
    formIsValid = false;
}
} 



  if(!formIsValid){
  setErrors(error) 
  }
  
  
  if(formIsValid) {
  

      LoadingModal()
   const fd = new FormData();
      Object.entries(client).forEach(([key, value]) => {
              fd.append(key, String(value));
          });
  
 
          fd.append('code', clientCode);

     let url = ServerUrl+'/client/create';
    axios.post(url, fd, config)
    .then(response =>{
      if(response.data.status === 200){


setErrors({...errors, successMessage:response.data.message})
    
handleReset()

                } else{

setErrors({...errors, errorMessage:response.data.message})
                }   
            })
            .catch((error)=>{
             ErrorModal('oops', error.message)
            }).finally(()=>{
              closeModal()
            }) 
      }
  
  }


  const ValidateExistence=()=>{
    if(client.email_address!==''){
          
        let url = ServerUrl+'/client/existence/'+client.email_address;
        axios.get(url, config).then(response=>{
            if(response.data.status===200){
                setErrors({...errors, email_address:client.email_address + ' already exist'})
                setClient({...client, email_address:''});
                return response.data;
            }
      })
    }
}

  

const fetchProfile = async()=>{
  let url = ServerUrl+'/company/profile'
    await  axios.get(url,  config).then((result:any)=>{
      if(result.data.data&&result.data.data.length!==0){
      
      setSettings(result.data.data)
      
      }
      })
      .catch((error)=>{
       //ErrorModal('oops', error.message)
      })
    }


useEffect(()=>{  
   fetchProfile() 
   var refferer = new URLSearchParams(window.location.search).get("ref")

if(refferer!==null){
  setClient({...client, referer_no:String(refferer)})
}
  
if(Token!==undefined){
window.open('/dashboard', '_self')
   
 }   
},[]); 

  return (<> 


<Helmet>
         <title>EKI CONNECT</title>
        <meta name="description" content={'EKI CONNECT is a communication digital product of Eki Digital Multipurpose Company,and we believe in revolutionizing the way people connect and share their contact information. We are a leading provider of NFC smart digital business cards, empowering individuals and businesses to effortlessly manage their professional networks in the digital age.'} />
       
    
        <meta property="og:title" content={'EKI CONNECT'}/> 
        <meta property="og:description" content={'EKI CONNECT is a communication digital product of Eki Digital Multipurpose Company,and we believe in revolutionizing the way people connect and share their contact information. We are a leading provider of NFC smart digital business cards, empowering individuals and businesses to effortlessly manage their professional networks in the digital age.'} /> 
        <meta property="og:image" content={ImagesUrl+'/about/logo.png'} />  

        <meta property="og:url" content={FrontUrl}/> 
      
<meta property="og:type"          content="website" />

    
<link rel="icon" href={ImagesUrl+'/about/logo.png'} />
        <meta name="twitter:title" content={'EKI CONNECT'} /> 
        <meta name="twitter:description" content={'EKI CONNECT is a communication digital product of Eki Digital Multipurpose Company,and we believe in revolutionizing the way people connect and share their contact information. We are a leading provider of NFC smart digital business cards, empowering individuals and businesses to effortlessly manage their professional networks in the digital age.'}/> 
        <meta name="twitter:image" content={ImagesUrl+'/about/logo.png'} />  
        <meta name="twitter:url" content={FrontUrl} />  

    </Helmet>

  <header className='sticky-top mb-1'>
    <div className="px-3 py-2 bg-dark text-white">
      <div className="container">
        <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">

       


          <a href="/" className="d-flex align-items-center my-2 my-lg-0 me-lg-auto text-white text-decoration-none">  
          <img onError={(e)=>{(e.target as HTMLImageElement).onerror = null; (e.target as HTMLImageElement).src =ImagesUrl+'/'+'no.png'}}  src={ImagesUrl+"/about/"+settings.image_url} alt="" className='img-fluid img-80s ' width={'80'} height={'50'}   />
{/* 
<span className="d-none d-lg-block">{settings.business_name}</span> */}
          </a>

          <ul className="nav col-12 col-lg-auto my-2 justify-content-center my-md-0 text-small">
            <li>
              <a href="/forgot_password" className="nav-link text-white f-14">
                <i className="fa fa-times"></i>&nbsp;
                Forgot Password
              </a>
            </li>

            <li>
              <a href="/logout" className="nav-link text-white f-14 ml-1">  <i className="fa fa-sign-in-alt"></i>&nbsp;
                Log In
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </header>
    <main>
     
   

  <section className="testimonial py-5 " >
    <div className="container">
        <div className="row ">
            <div className="col-md-4 py-5 bg-brand text-white text-center ">
                <div className=" ">
                    <div className="card-body">
                        <img src="/card2.svg" className='img-fluid' />
                        <h2 className="py-3">Eki Co-net</h2>
                        <h5>You must give a valid email address for the account to be associated with. This email address will be used to communicate with you, so it should be one that you have access to. <br/> 
                        
                        <br/> <br/> Also, we'll send you a verification link and send you a confirmation message when you create an account. To confirm your registration, look for activation email in your inbox or spam folder.
</h5>
                    </div>
                </div>
            </div>
            <div className="col-md-8 p-5 border">
                <h4 className="pb-4">New User Registration Form</h4>
                <hr />
                <form className='row'>  
                  




                


                <div className="col-md-6 mb-1">
                      <label  className="form-label">Prefix  (Optional)</label>

                      
                  <Select  options={[ 
{value:'', label:''},
{value:'Apostle', label:'Apostle'},
{value:'Mr.', label:'Mr.'},
{value:'Mrs.', label:'Mrs.'}, 
{value:'Ms.', label:'Ms.'}, 
 {value:'Dr', label:'Dr.'}, 
 {value:'General', label:'General'}, 
{value:'Esquire', label:'Esquire'},
{value:'Honorable', label:'Hon.'}, 
{value:'Mallam', label:'Mallam'},
{value:'Pst.', label:'Pst.'},
{value:'Junior', label:'Jr.'}, 
{value:'Messrs.', label:'Messrs.'},
{value:'Mmes.', label:'Mmes.'}, 
{value:'Msgr.', label:'Msgr.'},
{value:'Prof.', label:'Prof.'}, 
{value:'Rev.', label:'Rev.'},
{value:'Rt. Hon.', label:'Rt. Hon.'}, 
{value:'Sr.', label:'Sr.'}, 
{value:'St.', label:'St.'}
           ]}   
styles={styles}
onChange={handleSelect}  name="prefix" value={{value:client.prefix, label:client.prefix}} 
className={errors.prefix?"form-control form-control-danger form-control-lg":""}
/>
                    </div>


                <div className="col-md-6 mb-1">
                      <label  className="form-label">Firstname <span className='text-danger'>*</span></label>

                      <input type="text"  name="firstname" required autoComplete='off' role={'presentation'} value={client.firstname} onChange={handleChange} className={errors.firstname  ? 'form-control form-control-danger form-control-lg' : 'form-control form-control-lg'} /> 
                      {errors.firstname&&
                        <div className="col-form-label text-danger">{errors.firstname&&errors.firstname} </div>}
                    </div>



                    <div className="col-md-6 mb-1">
                      <label  className="form-label">Middlename (Optional)</label>

                      <input type="text"  name="middlename" required autoComplete='off' role={'presentation'} value={client.middlename} onChange={handleChange} className='form-control form-control-lg' /> 
                    
                    </div>



                    <div className="col-md-6 mb-1">
                      <label  className="form-label">Lastname <span className='text-danger'>*</span></label>

                      <input type="text"  name="lastname" required autoComplete='off' role={'presentation'} value={client.lastname} onChange={handleChange} className={errors.lastname  ? 'form-control form-control-danger form-control-lg' : 'form-control form-control-lg'} /> 
                      {errors.lastname&&
                        <div className="col-form-label text-danger">{errors.lastname&&errors.lastname} </div>}
                    </div>


                    <div className="col-md-6 mb-1">
                      <label  className="form-label">Username <span className='text-danger'>*</span> </label>

                      <input type="text"  name="username" required autoComplete='off' role={'presentation'} value={client.username} onChange={handleChange} className={errors.username  ? 'form-control form-control-danger form-control-lg' : 'form-control form-control-lg'} /> 
                      {errors.username&&
                        <div className="col-form-label text-danger">{errors.username&&errors.username} </div>}
                    </div>


<hr/>

                    <div className="col-md-6 mb-1">
                      <label  className="form-label">Email Address <span className='text-danger'>*</span> </label>

                      <input type="email" id="email" onBlur={ValidateExistence} name="email_address" required autoComplete='off' role={'presentation'} value={client.email_address} onChange={handleChange} className={errors.email_address  ? 'form-control form-control-danger form-control-lg' : 'form-control form-control-lg'} /> 
                      {errors.email_address&&
                        <div className="col-form-label text-danger">{errors.email_address&&errors.email_address} </div>}
                    </div>


                    <div className="col-md-6 mb-1">
                      <label  className="form-label">Password <span className='text-danger'>*</span> </label>

                      <div className="input-group">
                      <input type={client.is_hidden?'password':'text'} id="password" name="password" required autoComplete='off' role={'presentation'} value={client.password} onChange={handleChange} className={errors.password  ? 'form-control form-control-danger form-control-lg' : 'form-control form-control-lg'} /> 

                     <span className='btn btn-primary ' onClick={()=>setClient({...client, is_hidden:!client.is_hidden})} > <i className={client.is_hidden?'fa fa-eye fa-2x text-white':'fa fa-eye-slash text- fa-2x'}></i></span>
							
							</div>

                    </div>
                 





<hr />

<div className="col-md-6 mb-1">
                      <label  className="form-label">How do you hear about us <span className='text-danger'>*</span> </label>

                      
                  <Select  options={[ 

{value:'Social Media', label:'Social Media'},
{value:'Newspaper', label:'Newspaper'}, 
{value:'Television', label:'Television'}, 
 {value:'Radio', label:'Radio'}, 
{value:'Representative', label:'Representative'},
{value:'Others', label:'Others'}
           ]}   
styles={styles}
onChange={handleSelect}  name="referer" value={{value:client.referer, label:client.referer}} 
className={errors.referer?"form-control form-control-danger form-control-lg":""}
/>
                    </div>


                   

{client.referer==='Representative'?
                    <div className="col-md-6 mb-1">
                      <label  className="form-label">Representative Name / Referral name. <span className='text-danger'>*</span></label>

                      <input type="text"  className={errors.referer_no?"form-control form-control-danger form-control-lg":"form-control form-control-lg"} name="referer_no" value={client.referer_no} onChange={handleChange} />
                    </div>:''}


                   
                   


<hr />

                    <div className="col-md-12 mb-1">
                      <label  className="form-label">Address</label>
                      <input type="text" className="form-control form-control-lg"   name="address" value={client.address} onChange={handleChange} />
                    <i>Includes Landmark and Neighborhood</i>
                    </div>


                
                    <hr/>


                    


      <div className="form-row">
          <div className="form-group">
              <div className="form-group">
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" value="" id="invalidCheck2" required />
                    <label className="form-check-label" htmlFor="invalidCheck2" >
                      <small>By clicking Submit, you agree to our <a href="https://ekiconet.digital/terms-and-privacy-policy/#terms" target='_blank'>Terms & Conditions</a> and <a href="https://ekiconet.digital/terms-and-privacy-policy/#privacy " target='_blank'>Privacy Policy</a>.</small>
                    </label>
                  </div>
                </div>
      
            </div>
      </div>




                  

			 {errors.errorMessage!==''?
				  <div className="alert alert-danger alert-dismissible fade show" role="alert">
          {errors.errorMessage}
            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
          </div>:''}
          
          {errors.successMessage!==''?
				  <div className="alert alert-success alert-dismissible fade show" role="alert">
          {errors.successMessage}
            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
          </div>:
                    <div className="row mt-2">
                      <div className="col-md-6">
                        <button type="button" onClick={handleSubmit} className="btn btn-primary btn-lg  btn-block mt-2"><i className='fa fa-save'></i> Create an Account</button>
                        </div>
                    </div>}
                </form>
            </div>
        </div>
    </div>
</section>
  </main>


<Footer />
    
    
</>
  )
}

export default AddUser