import { useState, ChangeEvent, FormEvent, useEffect} from 'react'
import axios from 'axios'
import { APP_NAME, FrontUrl, ImagesUrl, ServerUrl, Token, config, styles } from '../components/includes';
import Cookies from 'js-cookie'
import { ErrorModal, LoadingModal, SuccessModal, closeModal } from '../components/notify';
import Footer from '../components/Footer';

import Select, { components } from 'react-select'
import { Helmet } from 'react-helmet';
const AccountVerify =()=> {

  const [settings, setSettings] = useState([]as any)

  const [errors, setErrors] = useState({
    referer:'',
    country:'',
    firstname:'',
    lastname:'',
    username:'',
    email_address:'',
    prefix:'',
    allowExchange:'',
    password:'',
    referer_no:'',
    successMessage:'',
    errorMessage:''
  });


  

const fetchProfile = async()=>{
  let url = ServerUrl+'/company/profile'
    await  axios.get(url,  config).then((result:any)=>{
      if(result.data.data&&result.data.data.length!==0){
      
      setSettings(result.data.data)
      
      }
      })
      .catch((error)=>{
       //ErrorModal('oops', error.message)
      })
    }


    function verifyApplicant(){  
		    
        
      var query = new URLSearchParams(window.location.search)
      let myemail:any = query.get("myemail")
     let mytoken:any = query.get("mytoken")
      
      if(!myemail || !mytoken || myemail==='null'  || mytoken==='null' || mytoken==='' || myemail===''){
          window.open('/', '_self')
      
      }else{

        LoadingModal()
         
            const fd = new FormData();
            fd.append('email', myemail)
            fd.append('token', mytoken)
      
            let url = ServerUrl+'/client/verify_user';
            axios.post(url, fd, config)
            .then(response =>{
              if(response.data.status === 200){
            
              setErrors({...errors, successMessage: response.data.message})
              setTimeout(() => {
                window.open('/', '_self')
               }, 2000);

            }else if (response.data.type ==='error'){
                    setErrors({...errors, errorMessage: response.data.message})
                    setTimeout(() => {
                      window.open('/', '_self')
                     }, 2000);
            }    
          })
          .catch((error)=>{
            setErrors({...errors, errorMessage: error.message})
    
             setTimeout(() => {
              window.open('/', '_self')
             }, 2000);
          }).finally(()=>{
           closeModal()
              })    
          } 
      }
      
      
      
      useEffect(()=>{  
          verifyApplicant()
      },[]);


useEffect(()=>{  
   fetchProfile() 

if(Token!==undefined){
window.open('/dashboard', '_self')
   
 }   
},[]); 

  return (<> 


<Helmet>
         <title>EKI CONNECT</title>
        <meta name="description" content={'EKI CONNECT is a communication digital product of Eki Digital Multipurpose Company,and we believe in revolutionizing the way people connect and share their contact information. We are a leading provider of NFC smart digital business cards, empowering individuals and businesses to effortlessly manage their professional networks in the digital age.'} />
       
    
        <meta property="og:title" content={'EKI CONNECT'}/> 
        <meta property="og:description" content={'EKI CONNECT is a communication digital product of Eki Digital Multipurpose Company,and we believe in revolutionizing the way people connect and share their contact information. We are a leading provider of NFC smart digital business cards, empowering individuals and businesses to effortlessly manage their professional networks in the digital age.'} /> 
        <meta property="og:image" content={ImagesUrl+'/about/logo.png'} />  

        <meta property="og:url" content={FrontUrl}/> 
      
<meta property="og:type"          content="website" />

    
<link rel="icon" href={ImagesUrl+'/about/logo.png'} />
        <meta name="twitter:title" content={'EKI CONNECT'} /> 
        <meta name="twitter:description" content={'EKI CONNECT is a communication digital product of Eki Digital Multipurpose Company,and we believe in revolutionizing the way people connect and share their contact information. We are a leading provider of NFC smart digital business cards, empowering individuals and businesses to effortlessly manage their professional networks in the digital age.'}/> 
        <meta name="twitter:image" content={ImagesUrl+'/about/logo.png'} />  
        <meta name="twitter:url" content={FrontUrl} />  

    </Helmet>

  <header className='sticky-top mb-1'>
    <div className="px-3 py-2 bg-dark text-white">
      <div className="container">
        <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">

       


          <a href="/" className="d-flex align-items-center my-2 my-lg-0 me-lg-auto text-white text-decoration-none">  
          <img onError={(e)=>{(e.target as HTMLImageElement).onerror = null; (e.target as HTMLImageElement).src =ImagesUrl+'/'+'no.png'}}  src={ImagesUrl+"/about/"+settings.image_url} alt="" className='img-fluid img-80s ' width={'80'} height={'50'}   />
{/* 
<span className="d-none d-lg-block">{settings.business_name}</span> */}
          </a>

          <ul className="nav col-12 col-lg-auto my-2 justify-content-center my-md-0 text-small">
            <li>
              <a href="/reset_password" className="nav-link text-white f-14">
                <i className="fa fa-times"></i>&nbsp;
                Reset Password
              </a>
            </li>

            <li>
              <a href="/logout" className="nav-link text-white f-14 ml-1">  <i className="fa fa-sign-in-alt"></i>&nbsp;
                Log In
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </header>
    <main>
     
   

  <section className="testimonial py-5 " >
    <div className="container">
        <div className="row ">
           
            <div className="col-md-12 p-5 border">
                <h4 className="pb-4">User Registration Confirmation</h4>
                <hr />
                <form className='row'>  
                  

                {errors.successMessage!==''?
				 <div className="alert bg-success mt-3">
				 <p className="icontext text-white"><i className=" fa fa-check"></i>  {errors.successMessage}</p>
			 </div>:''}

			 {errors.errorMessage!==''?
				 <div className="alert bg-danger mt-3">
				 <p className="icontext text-white"><i className=" fa fa-check"></i>  {errors.errorMessage}</p>
			 </div>:''}


              

                </form>
            </div>
        </div>
    </div>
</section>
  </main>


<Footer />
    
    
</>
  )
}

export default AccountVerify