import React from 'react'
import Swal from 'sweetalert2'
//import { Store } from 'react-notifications-component';
import { ImagesUrl } from './includes';
import { title } from 'process';

//https://teodosii.github.io/react-notifications-component/
 //https://sweetalert2.github.io/recipe-gallery/sweetalert2-react.html

 export const  Alerts =(type:any, content:string, title?:string)=>{
  // 'default', 'success', 'info', 'warning', danger
 /*   Store.addNotification({
    message: content,
    type: type,
    title: title,
    insert: "top",
    container: "top-full",
    animationIn: ["animate__animated", "animate__fadeIn"],
  animationOut: ["animate__animated", "animate__fadeOut"],
  
    dismiss: {
      duration: 5000,
      onScreen: false,
      pauseOnHover: true
    }
  })  */
  
}


export const  LoadingModal =()=>{
  // use warning, error, success, info, and question as icon
  // use 'top', 'top-start', 'top-end', 'center', 'center-start', 'center-end', 'bottom', 'bottom-start', or 'bottom-end' as position
  Swal.fire({
    width: 300,
    allowOutsideClick:false,
    showConfirmButton:false,
    html:
    `<div> <img src=${ImagesUrl+"/loading.gif"} class='img-30 ' /> Please wait ... </div>`,
  })  
  
} 





export const  FetchingModal =()=>{
  // use warning, error, success, info, and question as icon
  // use 'top', 'top-start', 'top-end', 'center', 'center-start', 'center-end', 'bottom', 'bottom-start', or 'bottom-end' as position
  Swal.fire({
    width: 100,
    allowOutsideClick:false,
    imageUrl: ImagesUrl+"/loader.gif", 
    showConfirmButton:false,
  imageAlt: 'Loading',
    title: '',
  })  
  
} 

export const closeModal =()=>{
  Swal.close()
}

export const  ErrorModal =(title:string, text:string)=>{
  
  Swal.fire({
    icon: 'error',
    width: 600,
    allowOutsideClick:true,
    allowEscapeKey:true,
    allowEnterKey:true,
    showConfirmButton:true,
    confirmButtonColor: '#3085d6',
    confirmButtonText:'Retry',
    title: title,
    text: text
  })
  
} 


export const DeleteModal =()=>{

  Swal.fire({
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!'
  }).then((result) => {
    if (result.isConfirmed) {


      Swal.fire(
        'Deleted!',
        'Your file has been deleted.',
        'success'
      )

      
    }
  })
}


export const SuccessModal =(title:string)=>{
  Swal.fire({
    position: 'top-end',
    width:300,
    html: title,
    showConfirmButton: false,
    timer: 2000
  })
}


export  const CustomLoader =()=><div className="preloader3 loader-block">
<div className="circ1 loader-primary"></div>
<div className="circ2 loader-primary"></div>
<div className="circ3 loader-primary"></div>
<div className="circ4 loader-primary"></div>
</div>
