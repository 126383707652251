
import React, { useEffect, useState, FormEvent, ChangeEvent } from 'react'

import { Helmet } from 'react-helmet';
import axios from 'axios'
import { FormatNumber, ImagesUrl, ServerUrl, FrontUrl,  config, user_code, Token } from './includes';
const Seo = () => {

const [settings, setSettings] = useState([]as any)

    const fetchSettings = async()=>{
        let url = ServerUrl+'/company/view'
          await  axios.get(url,  config).then((result:any)=>{
            if(result.data.data&&result.data.data.length!==0){
            
            setSettings(result.data.data)
            
            }
            })
          }


    useEffect(()=>{
        fetchSettings()
      }, [])

  return (
  
<Helmet>
         <title> EKI CO-NET</title>
        <meta name="description" content={'EKI CO-NET is a communication digital product of Eki Digital Multipurpose Company,and we believe in revolutionizing the way people connect and share their contact information. We are a leading provider of NFC smart digital business cards, empowering individuals and businesses to effortlessly manage their professional networks in the digital age.'} />
       
    
        <meta property="og:title" content={settings.business_name}/> 
        <meta property="og:description" content={'EKI CONNECT is a communication digital product of Eki Digital Multipurpose Company,and we believe in revolutionizing the way people connect and share their contact information. We are a leading provider of NFC smart digital business cards, empowering individuals and businesses to effortlessly manage their professional networks in the digital age.'} /> 
        <meta property="og:image" content={ImagesUrl+'/about/logo.png'} />  

        <meta property="og:url" content={FrontUrl}/> 
      
<meta property="og:type"          content="website" />

    
<link rel="icon" href={ImagesUrl+'/about/logo.png'} />
        <meta name="twitter:title" content={settings.business_name} /> 
        <meta name="twitter:description" content={'EKI CONNECT is a communication digital product of Eki Digital Multipurpose Company,and we believe in revolutionizing the way people connect and share their contact information. We are a leading provider of NFC smart digital business cards, empowering individuals and businesses to effortlessly manage their professional networks in the digital age.'}/> 
        <meta name="twitter:image" content={ImagesUrl+'/about/logo.png'} />  
        <meta name="twitter:url" content={FrontUrl} />  

    </Helmet>
  )
}

export default Seo