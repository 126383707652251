import React, { useEffect, useState, FormEvent, ChangeEvent } from 'react'

import axios from 'axios'
import { FormatNumber, ImagesUrl, ServerUrl, FrontUrl,  config, user_code, Token } from './includes';
import { ErrorModal, LoadingModal, SuccessModal } from './notify';
import Seo from './Seo';
import { timeSince } from './GlobalFunction';
const Header = () => {

  const [errors, setErrors] = useState({
    password:'',
    confirm_password:'',
    old_password:'',
});

const [stat, setStat] = useState({
  data:[] as any,
  record:[]as any
})
const [content, setContent] = useState([]as any)
const [settings, setSettings] = useState([]as any)
const [profile, setProfile] = useState({
  password:'',
  confirm_password:'',
  old_password:'',
  is_hidden:true
});

const handleChange =(event:ChangeEvent<HTMLInputElement>)=>{
  let {name, value} = event.target;	
  setProfile({...profile, [name]:value});
 setErrors({...errors, [name]:''});
}


const handleSubmit =(event: FormEvent<HTMLButtonElement>, closeid:string)=>{
  event.preventDefault();

  let error = {...errors}; 
  let formIsValid = true;

let msg ='This field is required';


if(!profile.password){
  error.password=msg;
  formIsValid = false;
} 

if(!profile.old_password){
  error.old_password=msg;
  formIsValid = false;
} 



if(!profile.confirm_password){
  error.confirm_password=msg;
  formIsValid = false;
} 


if(profile.password !== profile.confirm_password){
  error.confirm_password=msg;
  formIsValid = false;
} 


if(!formIsValid){
  setErrors(error) 
  }
  
  
  if(formIsValid) {
  

    var pht = document.getElementById(closeid)
    pht?.click()


    LoadingModal()
    const fd = new FormData();
  
    Object.entries(profile).forEach(([key, value]) => {
      fd.append(key, String(value));
  });

         let url = ServerUrl+'/client/change_password/'+user_code;
         axios.post(url, fd, config)
         .then(response =>{
         if(response.data.status === 200){
          SuccessModal(response.data.message)

               } else{
               ErrorModal('', response.data.message)
               }   
             })
             .catch((error)=>{
            ErrorModal('oops', error.message)
             }) 
  }

}



const fetchUser = async()=>{
  if(Token !== undefined){

    let url = ServerUrl+'/clients/display/user/'+user_code
    await  axios.get(url,  config).then((result:any)=>{
     
      if(result.data.data&&result.data.data.length!==0){
      
      setContent(result.data.data)
      
      }
      })
      .catch((error)=>{
      window.open('/logout', '_self')
      })
      }else{
        window.open('/logout', '_self')
      } 
    }

    const fetchSettings = async()=>{
      let url = ServerUrl+'/company/view'
        await  axios.get(url,  config).then((result:any)=>{
          if(result.data.data&&result.data.data.length!==0){
          
          setSettings(result.data.data)
          
          }
          })
          .catch((error)=>{
           ErrorModal('oops', error.message)
          })
        }




        const fetchNotification = async()=>{
          let url = ServerUrl+'/card/notification/'+user_code
            await  axios.get(url,  config).then((result:any)=>{
           
              if(result.data.data&&result.data.data.length!==0){
              
              setStat({
                data:result.data.data,
                record:result.data.total.total_item
                
              })
              
              }
              })
            }


    useEffect(()=>{
      fetchUser()
      fetchSettings()
      fetchNotification()
    }, [])

  return (<>
   <Seo />

<header className='sticky-top mb-1'>
    <div className="px-3 py-2 bg-dark text-white">
      <div className="container">
        <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">

       


          <a href="/dashboard" className="d-flex align-items-center my-2 my-lg-0 me-lg-auto text-white text-decoration-none">  
              <img onError={(e)=>{(e.target as HTMLImageElement).onerror = null; (e.target as HTMLImageElement).src =ImagesUrl+'/'+'no.png'}}  src={ImagesUrl+"/about/"+settings.image_url} alt="" className='img-fluid img-80s ' width={'80'} height={'50'}   />
{/* 
<span className="d-none d-lg-block">{settings.business_name}</span> */}
          </a>


          <nav className="header-nav ms-auto ">
        <ul className="d-flex align-items-center nav">
    
          


          <li className="nav-item dropdown">

{stat.record>0?
  <button type="button" className="btn btn-primary position-relative btn-sm" data-bs-toggle="dropdown">
  <i className="fa fa-bell"></i>
    <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">{stat.record}</span>
</button>:[]}



    <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow notifications p-2">
      <li className="dropdown-header">
        You have {stat.record} new notifications
        <a href="/notifications"><span className="badge rounded-pill bg-primary p-2 ms-2">View all</span></a>
      </li>
      <li>
              <hr className="dropdown-divider" />
      </li>

{stat.data.slice(0,4).map((item:any, index:number)=>
      <li className="notification-item mb-1" key={index} >
        <a href={"/notifications#"+item.code} className='text-dark text-muted'>
        <i className="fa fa-info-circle text-brand"></i>&nbsp;{item.subject} - { timeSince(new Date(item.created_at))}
      
        </a>
      </li>)}


      <li>
              <hr className="dropdown-divider" />
      </li>
      <li className="dropdown-footer">
        <a href="/notifications">Show all notifications</a>
      </li>

    </ul>

  </li>

  <li>
              <a href="#!" data-bs-toggle="modal" data-bs-target="#modal-password" className="nav-link text-white f-14 ml-1">  <i className="fa fa-key"></i>&nbsp;
              </a>
            </li>

            <li>
              <a href="/logout" className="nav-link text-white f-14 ml-1">  <i className="fa fa-sign-out-alt"></i>&nbsp;
                Log Out
              </a>
            </li>
  </ul>


  
  </nav>


        </div>
      </div>
    </div>
  </header>







  <div className="modal fade" id="modal-password"  aria-modal="true" role="dialog">
			<div className="modal-dialog" role="document">
				<div className="modal-content">
        <div className="modal-header">
        <h6 className="modal-title text-bold">Change Password</h6>
        <button type="button" className="btn-close btn-sm" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
					<div className="modal-body ">



          <div className="row">


<div className="col-sm-12">


  <div className="mb-3">
    <label>Old Password</label>
    <input type={profile.is_hidden?'password':'text'} value={profile.old_password} name="old_password" className={errors.old_password?"form-control form-control-errors ":"form-control "} onChange={handleChange} />
  </div>


  <div className="mb-3">
    <label>New Password</label>
    
    <input type={profile.is_hidden?'password':'text'} value={profile.password} name="password" className={errors.password?"form-control form-control-errors ":"form-control "} onChange={handleChange} />
  </div>

  <div className="mb-3">
    <label>Confirm New Password</label>

   

    <input type={profile.is_hidden?'password':'text'} value={profile.confirm_password} name="confirm_password" className={errors.confirm_password?"form-control form-control-errors ":"form-control "} onChange={handleChange} />
  </div>



</div>
</div>
	</div>



					<div className="modal-footer text-center">
		
<button type='button' onClick={()=>setProfile({...profile, is_hidden:!profile.is_hidden})} className='btn btn-sm btn-primary m-l-1'> <i className={profile.is_hidden?'fa fa-eye text-white':'fa fa-eye-slash text-white'}></i></button>
          <button className='btn btn-secondary btn-sm' data-bs-dismiss="modal" aria-label="Close" id='closeHeader' type='button'>Close</button>
<button className='btn btn-success btn-sm' type='button' onClick={(e)=>handleSubmit(e, 'closeHeader')} >Proceed</button>
					
					</div>
				</div>
			</div>
		</div>
  </>
  )
}

export default Header