

    
export const timeSince=(date:any) =>{
    var current:any = new Date();
    let seconds:any = Math.floor((current - date) / 1000);
  
    var interval = seconds / 31536000;
  
    if (interval > 1) {
      return date.toISOString().slice(0,10)
      //return Math.floor(interval) + " years ago";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return date.toISOString().slice(0,10)
      //return Math.floor(interval) + " months ago";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      
      //return date.toISOString().slice(0,10)
      return Math.floor(interval) + " days ago";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " hours ago";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " minutes ago";
    }
    return Math.floor(seconds) + " seconds ago";
  }
  