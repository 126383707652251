import { useEffect, useState, FormEvent, ChangeEvent } from 'react'
import Header from '../components/Header';
import Footer from '../components/Footer';

import Swal from 'sweetalert2'
import axios from 'axios'
import { FrontUrl, ImagesUrl, ServerUrl, user_code,  config, configDownload, FormatNumber } from '../components/includes';
import QRCode from 'qrcode'
import { ErrorModal, LoadingModal, SuccessModal } from '../components/notify'

const ExchangeContact= () => {

  const [content, setContent] = useState([]as any)

  const fetchContent = async()=>{
    let url = ServerUrl+'/exchange/all/'+user_code
      await  axios.get(url,  config).then((result:any)=>{
        if(result.data.data&&result.data.data.length!==0){
            try{
        setContent(result.data.data)
    }catch(e){

    }
        }
        })
        .catch((error)=>{
       
        })
      }





const DeleteModal =(code:string)=>{

  let url = ServerUrl+`/exchange/delete/`+code;
    Swal.fire({
      title: 'Are you really sure?',
      text: "This is very dangerous, you shouldn't do it!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      showLoaderOnConfirm: true,
    preConfirm: async() => {
      return await axios.get(url,  config).then(response=>{
       if(response.data.status!==200){
            throw new Error(response.statusText)
          }
          return response.data
        })
        .catch(error => {
          Swal.showValidationMessage(
            `Request failed: ${error}`
          )
        })
    },
    allowOutsideClick: () => !Swal.isLoading(),
      confirmButtonText: 'Yes, I am!'
    }).then((result:any) => {
      if (result.isConfirmed && result.value.status===200) {
       fetchContent()
         Swal.fire(
                    'Deleted!',
                    'Record has been deleted.',
                    'success'
                  ) 
  
      }
    })
  }


      useEffect(()=>{
        fetchContent()
       }, []) 

  return (
<>
<Header />




<div className="page-content mt-4">
<div className="container">
<h5>Exchanged Contacts</h5>

<nav aria-label="breadcrumb">
  <ol className="breadcrumb">
    <li className="breadcrumb-item"><a href="/dashboard">Home</a></li>
    <li className="breadcrumb-item active" aria-current="page">Exchanged Contacts</li>
  </ol>
</nav>


<div className="row mt-4">
			

{content&&content.map((item:any, id:number)=>	<div key={id} className="col-md-12 col-xl-4">
					<div className="card custom-card">
						<div className="card-header d-flex justify-content-between  ">
							<h5 className=" tx-dark my-auto tx-medium mb-0">{item.fullname}
								</h5>

                <div className="dropdown">
  <button className="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
    Action
  </button>
  <ul className="dropdown-menu">
    <li><a className="dropdown-item" href={ServerUrl+"/exchange/download/"+user_code}>Download Contact</a></li>
    <li><a className="dropdown-item" href="#">Send Mail</a></li>
    <li><a className="dropdown-item" href={`tel:${item.telephone}`}>Call</a></li>
    <li><a className="dropdown-item" target='_blank' href={"https://api.whatsapp.com/send?phone="+item.telephone}>Whatsapp</a></li>
    <li><a className="dropdown-item" href="#!" onClick={()=>DeleteModal(item.code)} >Delete</a></li>
  </ul>
</div>



						</div>
						<div className="card-body ">
						{item.message}
							<br />
							<h5 className="main-content-label tx-dark tx-medium mg-b-10">{item.email}</h5>
							<h5 className="main-content-label tx-dark tx-medium mg-b-10">{item.telephone}</h5>

							<div className="d-flex align-items-center pt-3 mt-auto">
								
								<div>
									<b className="text-primary">{item.created_at}</b>

								</div>
								<div className="ml-auto text-muted">
																			<span className="label text-warning d-flex" style={{float: 'right'}}><span className="label text-warning mr-1"></span>&nbsp;&nbsp;{item.is_sent==='No'?'Pending':'Sent'}</span>
																										</div>
							
						</div>

					</div>
				</div>


						</div>)}
	</div>


</div>
</div>

<Footer />
</>
  )
}

export default ExchangeContact