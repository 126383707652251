import React from 'react'

const Error404 = () => {
  return (<>
   {/*  <div className="d-flex align-items-center justify-content-center vh-100">
    <div className="text-center">
        <h1 className="display-1 fw-bold">404</h1>
        <p className="fs-3"> <span className="text-danger">Opps!</span> Page not found.</p>
        <p className="lead">
            The page you’re looking for doesn’t exist.
          </p>
        <a href="/" className="btn btn-primary">Go Home</a>
    </div>
</div> */}


<div className="d-flex align-items-center justify-content-center vh-100">
            <div className="text-center row d-flex align-items-center justify-content-center">
                <div className=" col-md-6">
                    <img src="https://api.ekiconet.digital/public/images/about/logo.png"  width={'150'} />
                </div>
                <div className=" col-md-6 mt-5">
                    <p className="fs-3"> <span className="text-danger">Opps!</span> Page not found.</p>
                    <p className="lead">
                        The page you’re looking for doesn’t exist.
                    </p>
                    <a href="/" className="btn btn-primary">Go Home</a>
                </div>

            </div>
        </div>
        </>
  )
}

export default Error404