import Cookies from 'js-cookie'
export const ImagesUrl = process.env.REACT_APP_PUBLIC_IMAGE_URL;
export const ServerUrl = process.env.REACT_APP_SERVER_URL;
export const APP_NAME = process.env.REACT_APP_NAME;
export const APP_KEY = process.env.REACT_APP_KEY;
export const FrontUrl = process.env.REACT_APP_MAIN_URL;

export const user_code =Cookies.get('etiucd')?Cookies.get('etiucd'):undefined
export const Token:any = Cookies.get('etitk')?Cookies.get('etitk'):undefined
export const PSKLIVE = 'pk_live_67f3ef5a329f6cb7e4238b15e3939b39493bc08d';
export const PSKTEST = 'pk_test_e456d83a25992f457f2d6752a8e4cb4f267e0881';

export const currency = '₦';

export const config = {
    headers: { 'content-type': 'multipart/form-data',
    'Authorization': `Bearer ${Token}`}
} 

export   const styles = {
    container: (css:any) => ({ ...css,
         flex: "1 1 auto", 
         alignSelf: "stretch",
       
        
        }),
    menu: (css: any) => ({ ...css, zIndex: 999 }),
    control: (css:any) => ({ ...css, height:45 })
  };
  
export const configDownload:any = {
    responseType: 'blob', 
    headers: {
        
    'Authorization': `Bearer ${Token}`}
}
export const FormatNumber = (num:Number)=>{
    let newNumber = Number(num)
    return  newNumber.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export const generateCardNo=(t:number)=>{
    t||(t=16);
    for(var e="",a=0;a<t;a++){
      var n=Math.floor(Math.random()*"1234567890".length);
      e+="1234567890".substring(n,n+1)
      }
     
      return e
  }