import { useState, ChangeEvent, FormEvent, useEffect} from 'react'
import axios from 'axios'
import { APP_NAME, ImagesUrl, ServerUrl, Token } from '../components/includes';
import Cookies from 'js-cookie'
import { ErrorModal, LoadingModal, closeModal } from '../components/notify';
import Footer from '../components/Footer';

const ResetPassword =()=> {


  const [profile, setProfile] = useState([]as any)
	const [notice, setNotice] = useState({
    isLoading : false,
    isDataFetching: false
});

const [user, setUser] = useState({
email:'',
password:'',
confirmPassword:'',
})
const [errors, setErrors] = useState({
    email:'',
    message:'',
    password:'',
    confirmPassword:'',
    successMessage:'',
    errorMessage:''

});

const handleChange = (event:ChangeEvent<HTMLInputElement>)=>{
    let {name, value} = event.target;	
    if(name==='remember'){
        value= value==='Yes'?'No':'Yes'
    }	
   setUser({...user, [name] : value });
   setErrors({...errors, [name]:''});
}

const config = {
    headers: { 
        "Access-Control-Allow-Origin": "*",
        'content-type': 'multipart/form-data'
}
} 
  

const fetchProfile = async()=>{
  let url = ServerUrl+'/company/profile'
    await  axios.get(url,  config).then((result:any)=>{
      if(result.data.data&&result.data.data.length!==0){
      
      setProfile(result.data.data)
      
      }
      })
      .catch((error)=>{
       ErrorModal('oops', error.message)
      })
    }


    var query = new URLSearchParams(window.location.search)
    let myemail:any = query.get("myemail")
    let mytoken:any = query.get("mytoken")

const handleSubmit =(event: FormEvent<HTMLFormElement>)=>{
    event.preventDefault();

    let error = {...errors}; 
    let formIsValid = true;

let msg ='This field is required';




if(!user.password){
error.password =msg;
formIsValid = false;
} 


if(!user.confirmPassword){
  error.confirmPassword =msg;
  formIsValid = false;
  } 


if(!navigator.onLine){

  ErrorModal('', 'No internet connection')
    formIsValid = false;
} 


if(!formIsValid){
setErrors(error) 
}

if(formIsValid) {

LoadingModal()	
       
    const fd = new FormData();
    fd.append('email', myemail);
    fd.append('password', user.password);
       let url = ServerUrl+'/client/update_password';
axios.post(url, fd, config)
.then(response =>{
        if(response.data.status === 200){
          setErrors({...errors, successMessage: response.data.message})
          
          setTimeout(() => {
            window.open('/', '_self')
           }, 2000);
  }else{
    setErrors({...errors, errorMessage: response.data.message})
  }    
})
.catch((error)=>{
  setErrors({...errors, errorMessage: error.message})

}).finally(()=>{
    closeModal()
    })   
}
}




function verifyLink(){  
		    
        
  
  if(!myemail || !mytoken || myemail==='null'  || mytoken==='null' || mytoken==='' || myemail===''){
      window.open('/', '_self')
  
  }else{

    LoadingModal()
     
        const fd = new FormData();
        fd.append('email', myemail)
        fd.append('token', mytoken)
  
        let url = ServerUrl+'/client/verify_user';
        axios.post(url, fd, config)
        .then(response =>{
          if(response.data.status === 200){
        

        }else if (response.data.type ==='error'){
          ErrorModal('oops', 'Broken link or invalid link')
                setTimeout(() => {
                  window.open('/', '_self')
                 }, 2000);
        }    
      })
      .catch((error)=>{
        setErrors({...errors, errorMessage: error.message})

         setTimeout(() => {
          window.open('/', '_self')
         }, 2000);
      }).finally(()=>{
       closeModal()
          })    
      } 
  }


useEffect(()=>{ 
  verifyLink() 
   fetchProfile() 

},[]); 

  return (<> 
    <main>
    <div className="container">


    {errors.successMessage!==''?
				 <div className="alert bg-success mt-3">
				 <p className="icontext text-white"><i className=" fa fa-check"></i>  {errors.successMessage}</p>
			 </div>:''}

			 {errors.errorMessage!==''?
				 <div className="alert bg-danger mt-3">
				 <p className="icontext text-white"><i className=" fa fa-check"></i>  {errors.errorMessage}</p>
			 </div>:''}

      <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">

              <img onError={(e)=>{(e.target as HTMLImageElement).onerror = null; (e.target as HTMLImageElement).src =ImagesUrl+'/'+'no.png'}}  src={ImagesUrl+"/about/"+profile.image_url} alt=""  width={'150'}  /><br/>
              <div className="card mb-3">

                <div className="card-body">

                  <div className=" pb-2">
                    <h5 className="card-title text-center pb-0 fs-4">Change Password</h5>
                  </div>

                  <form className="row g-3 needs-validation" onSubmit={handleSubmit} >

                    <div className="col-12">
                      <label  className="form-label">Enter New Password</label>

                      <input type="password" id="password" name="password" required autoComplete='off' role={'presentation'} value={user.password} onChange={handleChange} className={errors.password  ? 'form-control formerror ' : 'form-control '} /> 
                      {errors.password&&
                        <div className="col-form-label has-danger">{errors.password&&errors.password} </div>}
                  
                    </div>

            

                    <div className="col-12">
                      <label  className="form-label">Confirm New Password</label>

                      <input type="password" id="confirmPassword" name="confirmPassword" required autoComplete='off' role={'presentation'} value={user.confirmPassword} onChange={handleChange} className={errors.confirmPassword  ? 'form-control formerror ' : 'form-control '} /> 
                      {errors.confirmPassword&&
                        <div className="col-form-label has-danger">{errors.confirmPassword&&errors.confirmPassword} </div>}
                  
                    </div>

                   
                    <div className="col-12">

                    {notice.isLoading ?	<button type="button" disabled className="btn btn-primary w-100"><i className='bi bi-refresh bi-spin'></i> Please wait ... </button>:
				<button type="submit"   className="btn btn-primary w-100"><i className='bi bi-arrow-right '></i> Change Password</button>}


                    </div>
                 

                    <div className="col-12">
                      <div className="form-check">
               
                        <a href='/' className="form-check-label" >Back to Login </a>
                      </div>
                    </div>

                  </form>

                </div>
              </div>


            </div>
          </div>
        </div>

      </section>

    </div>
  </main>

<Footer />
    
    
</>
  )
}

export default ResetPassword