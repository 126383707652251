import React from 'react'
import { Token } from './includes'

const Footer = () => {
  return (
    <footer className="pt-4 pb-4 text-muted text-center d-print-none">
    <div className="container">
    <div className="text-small">
    <div className="mb-1">© Copyright {new Date().getFullYear()}. All Rights Reserved. <b>Eki Digital Mult. Coy. Ltd.</b></div>
    <div> {Token!==undefined?<a href="/logout" >Logout</a>:<a href="/" >Login</a>}</div>
    </div>
    </div>
    </footer>
  )
}

export default Footer