import React, { useEffect, useState, FormEvent, ChangeEvent } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Joyride, { CallBackProps, STATUS, Step }  from 'react-joyride'
import Select from 'react-select'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { FormatNumber, ImagesUrl, ServerUrl,  config, user_code } from '../components/includes';

import Swal from 'sweetalert2'
import { ErrorModal, LoadingModal, SuccessModal } from '../components/notify'

const EditHomepage = () => {
  let params = useParams()

  const [errors, setErrors] = useState({
    image:'',
    firstname:'',
    lastname:'',
    title:'',
    sorting:'',
    infotext:'',
    description:'',
    types:'',
    group_name:''
});

const [steps, setSteps] = useState({
  run: false,
  steps: [{
    target: '.tour-1-profile',
    title:'Profile Header',
    content: 'Click to edit your own profile information, including name, position, and other little details.',
    disableBeacon: true
  },
  {
    target: '.tour-2-vcf',
    title:'Virtual Contact File Settings',
    content: 'You can activate or disable the option for visitors to save your contact information here. ',
  },
  {
    target: '.tour-3-email',
    content: 'Click here to allow visitors to request sharing your contact information via email,',   
    title:'Exchange Contact via Email'
  },
  {
    target: '.tour-4-rating',
    content: 'You can invite visitors to rate your work or services in this section. Enter the rating link here.',   
    title:'Google Rating'
  },
  {
    target: '.tour-5-photo',
    content: 'To update your profile image, click this link. The allowed limit is 3 MB.',   
    title:'Profile Image'
  },
  {
    target: '.tour-6-media',
    content: 'This is where you may select which social media fields to set as default. To be able to do that, you must first add such field.',   
    title:'Default Social Media'
  },
  {
    target: '.tour-7-section',
    content: 'Click here to add a new group or new footer content, such as opening hours or corporate information.',   
    title:'New Section'
  },
  {
    target: '.tour-8-edit',
    content: 'Click here to change the group name and display order',   
    title:'Modify Section'
  },
  {
    target: '.tour-9-remove',
    content: 'To delete the group, click this link; once you do, all other fields under the group will be removed as well.',   
    title:'Remove Section'
  },
  {
    target: '.tour-10-new',
    content: 'Click here to  add a new field to a group',   
    title:'Add New Field'
  },
  {
    target: '.tour-11-theme',
    content: 'Click here to enable or disable the dark mode theme',   
    title:'Change Theme'
  },
  {
    target: '.tour-12-share',
    content: 'Click here to create a profile barcode that visitors can share',   
    title:'Share Profile'
  },
  {
    target: '.tour-13-dashboard',
    content: 'Click here to access the Dashboard and view other features that are accessible',   
    title:'Dashboard'
  }
  ],
  placement: 'bottom',
});

const [mode, setMode] = useState ('light')

   const [profile, setProfile] = useState ({
     filePath:'', 
     image:'',
     passport:'',
     about:'',
    address:'',
    city:'',
    company:'',
    country:'',
    firstname:'',
    middlename:'',
    job_title:'',
    lastname:'',
    more_info:'',
    position:'',
    prefix:'' as any,
    region:'',
    role:'',
    street:'',
    addContact:true,
    isSection:false,
    title:'',
    sorting:'',
    infotext:'',
    allowExchange:'Yes',
    allowRating:'Yes',
    telephone:'',
    rating_link:''

   })

   const [groupInfo, setGroupInfo] = useState({
    types:[] as any,
    title:'',
    link:'',
    description:'',
    sorting:'',
    group_code:'',
    group_name:'',
    group_title:'',
    user_code:params.code,
    show_on_profile:'Yes',
    show_on_vcf:'Yes',
    photo:'',
    rating_link:'',
    placeholder:''
   })

  const [content, setContent] = useState({

prefix:'',	
company:'',	
role:'',	
job_title:'',	
country:'',	
region:'',	
city:'',	
street:'',	
address:'',	
more_info:'',	
about:'',	
photo:'',	
firstname:'',	
lastname:'',	
middlename:'',	
username:'',	
position:'',	
email_address:'',	
expiry_date:'',	
allowExchange:'',	
staff_create:'',
  })
  const [items, setItems] = useState([]as any)
  const [groupItems, setGroupItems] = useState([]as any)
  const [group, setGroup] = useState([]as any)
  const [groupFooter, setGroupFooter] = useState([]as any)


  const [media, setMedia] = useState ({
    photo:'', 
    title:'',
    type:'',
   data:{} as any,
   list:[] as any,
   errorMessage:''
  })

  const styles = {
    container: (css:any) => ({ ...css,
         flex: "1 1 auto", 
         alignSelf: "stretch",
        
        }),
    menu: (css: any) => ({ ...css, zIndex: 999, background:mode==='dark'? 'black':'', color:mode==='dark'?'white':'' }),
    control: (css:any) => ({ ...css })
  };

  const fetchGroup = async()=>{
    let url = ServerUrl+'/groups/display/user/'+params.code
      await  axios.get(url,  config).then((result:any)=>{
        if(result.data.data&&result.data.data.length!==0){

          setGroup(result.data.data.sort((a:any, b:any) =>(a.sorting > b.sorting) ? 1 : -1))
        }
        })
        .catch((error)=>{
       
        })
      }

      const fetchGroupFooter = async()=>{
        let url = ServerUrl+'/groups/footer/user/'+params.code
          await  axios.get(url,  config).then((result:any)=>{
            if(result.data.data&&result.data.data.length!==0){
              setGroupFooter(result.data.data.sort((a:any, b:any) =>(a.sorting > b.sorting) ? 1 : -1))
            }
            })
            .catch((error)=>{
           
            })
          }


  const fetchItems = async()=>{
    let url = ServerUrl+'/items/display/all'
      await  axios.get(url,  config).then((result:any)=>{
        if(result.data.data&&result.data.data.length!==0){
          setItems(result.data.data)
        }
        })
        .catch((error)=>{
       
        })
      }

      const fetchGroupItems = async()=>{
        let url = ServerUrl+'/groups/item/display/all/'+params.code
          await  axios.get(url,  config).then((result:any)=>{
            if(result.data.data&&result.data.data.length!==0){
             
              setGroupItems(result.data.data.sort((a:any, b:any) =>(a.sorting > b.sorting) ? 1 : -1))

            }
            })
            .catch((error)=>{
           
            })
          }


  const fetchContent = async()=>{
    let url = ServerUrl+'/clients/display/user/'+params.code
      await  axios.get(url,  config).then((result:any)=>{
        if(result.data.data&&result.data.data.length!==0){
       
        setContent(result.data.data)
        setProfile({...profile,
          title:'', 
          sorting:'',
          infotext:'',
          ...result.data.data})
        }else{
          window.open('/404', '_self');
        }
        })
        .catch((error)=>{
       
        })
      }

      const handleEditItem =(row:any, link:string)=>{

        setGroupInfo({
          ...groupInfo,
          group_code:row.code,
          title:row.title,
          group_title:row.title,
          description:row.description,
          link:row.link,
          sorting:row.sorting,
          show_on_profile:row.show_on_page,
          show_on_vcf:row.show_on_contact,
          photo:row.photo,
          placeholder:row.placeholder
        })
        var pht = document.getElementById(link)
        pht?.click()
      }


      const getItemtype=(type:string)=>{
        let itm = groupItems.filter((list:any)=>list.item_title===type)
        let ans = itm.filter((list:any)=>list.is_default==='Yes')
        return ans.length!==0?{ value:ans[0].code, label:ans[0].description, type:ans[0].title,  item_code:ans[0].item_code}:{value:'', label:'', type:'', item_code:''}

      }
      const handleEditMedia =(data:any, link:string)=>{

        setMedia({
          ...media,
          photo:data.photo,
          title:data.title,
          type:data.type,
          list:groupItems.filter((list:any)=>list.item_title===data.type),
          data:getItemtype(data.type),
          errorMessage:groupItems.filter((list:any)=>list.item_title===data.type).length===0?`Insert ${data.type} in your contact details to enable this feature`:'',


        })
        var pht = document.getElementById(link)
        pht?.click()
      }



      const handleEditGroup =(row:any)=>{

        setGroupInfo({
          ...groupInfo,
          group_code:row.code,
          group_name:row.title,
          group_title:row.title,
          sorting:row.sorting,
          show_on_profile:row.show_profile
        })
        var pht = document.getElementById('updateGroup')
        pht?.click()
      }


      const handleAddItem =(row:any)=>{

        let sorting = groupItems.filter((data:any)=>data.group_code===row.code)
        setGroupInfo({
          ...groupInfo,
          title:'',
          sorting:sorting.length+1,
          link:'',
          description:'',
          group_code:row.code,
          group_name:row.title,
        })
        var pht = document.getElementById('addItem')
        pht?.click()


      }



const handleOpenSorting =(openid:string, item:any)=>{

  setProfile({...profile, sorting:item.length+1})
  var pht = document.getElementById(openid)
        pht?.click()
}


      const handleSelectItem = (option:any, action:any)=>{
        setGroupInfo({...groupInfo, [action.name]:option, link:'', description:''});
          setErrors({...errors, [action.name]:''})
           }

           const handleSelectMedia = (option:any, action:any)=>{
            setMedia({...media, [action.name]:option});
               }


               const handleChangeDescription =(event:ChangeEvent<HTMLInputElement>,type:String)=>{
                let {name, value} = event.target;	
               

                setGroupInfo({...groupInfo, [name]:value});
               setErrors({...errors, [name]:''});
             }

           const handleChangeItem =(event:ChangeEvent<HTMLInputElement>)=>{
            let {name, value} = event.target;	
            setGroupInfo({...groupInfo, [name]:value});
           setErrors({...errors, [name]:''});
         }


      const handleSelect = (option:any, action:any)=>{
        setProfile({...profile, [action.name]:option.value});
          setErrors({...errors, [action.name]:''})
           }


           const handleChange =(event:ChangeEvent<HTMLInputElement>)=>{
            let {name, value} = event.target;	
            setProfile({...profile, [name]:value});
           setErrors({...errors, [name]:''});
         }


         const handleChangeArea =(event:ChangeEvent<HTMLTextAreaElement>)=>{
          let {name, value} = event.target;	
          setProfile({...profile, [name]:value});
         setErrors({...errors, [name]:''});
       }


       const handleChangeAreaItem =(event:ChangeEvent<HTMLTextAreaElement>)=>{
        let {name, value} = event.target;	
        setGroupInfo({...groupInfo, [name]:value});
       setErrors({...errors, [name]:''});
     }

      const  handleFile = function(fieldName:any){
        return function(newValue:any){                 
       readURL(newValue);
       }
      } 


const handleUpdateMedia =(event: FormEvent<HTMLButtonElement>, close:string)=>{
  event.preventDefault();

  let error = {...errors}; 
  let formIsValid = true;


if(media.data.type===''){
  setMedia({...media, errorMessage:'Please select item'})
  formIsValid = false;
}


  if(formIsValid) {
  
    LoadingModal()
    const fd = new FormData();
    
      fd.append('type', media.data.item_code);
      fd.append('code', media.data.value);

         let url = ServerUrl+'/groups/item/default/'+params.code;
         axios.post(url, fd, config)
         .then(response =>{
         if(response.data.status === 200){
          SuccessModal(response.data.message)

         
          handleAllItems()
          setMedia({
            photo:'', 
            title:'',
            type:'',
           data:{value:'', label:'', type:'', item_code:''},
           list:[] as any,
           errorMessage:'',
           })

          var pht = document.getElementById(close)
          pht?.click()

               } else{
               ErrorModal('', response.data.message)
               }   
             })
             .catch((error)=>{
            ErrorModal('oops', error.message)
             }) 
  }
}


      const readURL = (input:any)=>{

        let displayMessage = '';
        
        const PreviewImage = input.target.name;
        const inputElement = document.getElementById('logo') as HTMLInputElement
        const fileName = inputElement.value
        
        var idxDot = fileName.lastIndexOf(".") + 1;
        var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
        
        if (extFile==="jpg" || extFile==="jpeg" || extFile==="png" || extFile==="JPG" || extFile==="JPEG" || extFile==="PNG" ){
            //TO DO    
            
             var FileSize = input.target.files[0].size / 1024 / 1024; // in MB
        if (FileSize > 4) {
            displayMessage = ' File uploaded is larger than maximum size Allow (4 mb).';
            inputElement.value = '';
         
        
        } else {
            if (input.target.files && input.target.files[0]) { //Check if input has files.
                var reader = new FileReader();//Initialize FileReader.
                reader.onload = function (e:any) {

          

                //Check if input has files.
                let Id = Math.random().toString(36).substring(2, 9)
                 let fileUrl = Id+'.png'
        
                 setProfile({...profile,
                    filePath:fileUrl, 
                    passport:e.target.result, 
                    image:input.target.files[0]
                  }) 
        
                }
                reader.readAsDataURL(input.target.files[0]);
                
            }
        }
        }else{
        displayMessage = 'Only JPEG|PNG|JPG  file format are allowed with maximum of 4mb'
        inputElement.value = '';
        setProfile({...profile, filePath:'', image:'', passport:''})
        }   
        
        if(displayMessage.length > 0){
       ErrorModal("", displayMessage)
        }
        }




        const handleSubmitInfo =(event: FormEvent<HTMLButtonElement>, closeid:string)=>{
          event.preventDefault();
        
          let error = {...errors}; 
          let formIsValid = true;
        
        let msg ='This field is required';
        
      
        if(!profile.firstname){
          error.firstname=msg;
          formIsValid = false;
        } 
      
        if(!profile.lastname){
          error.lastname=msg;
          formIsValid = false;
        } 
        
      
        if(!formIsValid){
          setErrors(error) 
          }
          
          
          if(formIsValid) {
          
            LoadingModal()
            const fd = new FormData();
          
            Object.entries(profile).forEach(([key, value]) => {
              fd.append(key, String(value));
          });

                 let url = ServerUrl+'/client/update/'+params.code;
                 axios.post(url, fd, config)
                 .then(response =>{
                 if(response.data.status === 200){
                  SuccessModal(response.data.message)
      
                  var pht = document.getElementById(closeid)
                  pht?.click()
                  handleAllItems()
      
      
                       } else{
                       ErrorModal('', response.data.message)
                       }   
                     })
                     .catch((error)=>{
                    ErrorModal('oops', error.message)
                     }) 
          }
      
      }

      


	const handleSubmit =(event: FormEvent<HTMLButtonElement>)=>{
		event.preventDefault();
	
		let error = {...errors}; 
		let formIsValid = true;
	
	let msg ='This field is required';
	

	if(!profile.image){
		error.image=msg;
		formIsValid = false;
	} 

  
	

	if(!formIsValid){
		setErrors(error) 
    ErrorModal("", 'Upload profile image')
		}
		
		
		if(formIsValid) {
		
			LoadingModal()
			const fd = new FormData();
		

				   if(profile.image){
					fd.append('image', profile.image)
				   }
           fd.append('photo', content.photo)
           fd.append('code', String(params.code))
				   let url = ServerUrl+'/client/image/add/'+params.code;
				   axios.post(url, fd, config)
				   .then(response =>{
					 if(response.data.status === 200){
					  SuccessModal(response.data.message)

            handleAllItems()
        
            var pht = document.getElementById('closephoto')
            pht?.click()
            


							   } else{
								 ErrorModal('', response.data.message)
							   }   
						   })
						   .catch((error)=>{
							ErrorModal('oops', error.message)
						   }) 
		}

}



const handleSubmitGroupItem =(event: FormEvent<HTMLButtonElement>, close:string)=>{
  event.preventDefault();

  let error = {...errors}; 
  let formIsValid = true;

let msg ='This field is required';


if(groupInfo.types.length==0){
  error.types=msg;
  formIsValid = false;
}


if(!groupInfo.title){
  error.title=msg;
  formIsValid = false;
}
  

/* if(!groupInfo.description){
  error.description=msg;
  formIsValid = false;
} */
  


if(!formIsValid){
  setErrors(error) 
  }

  if(formIsValid) {
  
    LoadingModal()
    const fd = new FormData();
    

         Object.entries(groupInfo).forEach(([key, value]) => {
          fd.append(key, String(value));
      });

      fd.append('type', groupInfo.types.value);

         let url = ServerUrl+'/group/item/add';
         axios.post(url, fd, config)
         .then(response =>{
         if(response.data.status === 200){
          SuccessModal(response.data.message)

         
          handleAllItems()
          setGroupInfo({
        ...groupInfo,
            types:'',
            title:'',
            link:'',
            description:'',
            sorting:'',
            group_code:'',
            group_name:'',
            show_on_profile:'Yes',
            show_on_vcf:'Yes'
           })

          var pht = document.getElementById(close)
          pht?.click()

               } else{
               ErrorModal('', response.data.message)
               }   
             })
             .catch((error)=>{
            ErrorModal('oops', error.message)
             }) 
  }
}



const handleUpdateItem =(event: FormEvent<HTMLButtonElement>, close:string)=>{
  event.preventDefault();

  let error = {...errors}; 
  let formIsValid = true;

let msg ='This field is required';


if(!groupInfo.title){
  error.title=msg;
  formIsValid = false;
}
  
  
/* if(!groupInfo.description){
  error.description=msg;
  formIsValid = false;
} */


if(!formIsValid){
  setErrors(error) 
  }

  if(formIsValid) {
  
    LoadingModal()
    const fd = new FormData();
    
     


         fd.append('title', groupInfo.title)
         fd.append('description', groupInfo.description)
         fd.append('sorting', groupInfo.sorting)
         fd.append('link', groupInfo.link)
         fd.append('show_on_page', groupInfo.show_on_profile)
         fd.append('show_on_contact', groupInfo.show_on_vcf)
         let url = ServerUrl+'/group/item/update/'+groupInfo.group_code;
         axios.post(url, fd, config)
         .then(response =>{
         if(response.data.status === 200){
          SuccessModal(response.data.message)

          handleAllItems()
          var pht = document.getElementById(close)
          pht?.click()

          setGroupInfo ({...groupInfo,
            title:'',
            sorting:'',
            group_code:'',
            group_name:'',
            group_title:'',
            show_on_profile:'Yes',
            show_on_vcf:'Yes',
            description:'',
            link:''
          })

               } else{
               ErrorModal('', response.data.message)
               }   
             })
             .catch((error)=>{
            ErrorModal('oops', error.message)
             }) 
  }
}

const handleSubmitItem =(event: FormEvent<HTMLButtonElement>, close:string)=>{
  event.preventDefault();

  let error = {...errors}; 
  let formIsValid = true;

let msg ='This field is required';


if(!profile.title){
  error.title=msg;
  formIsValid = false;
}
  
  
if(!profile.infotext){
  error.infotext=msg;
  formIsValid = false;
}


if(!formIsValid){
  setErrors(error) 
  }

  if(formIsValid) {
  
    LoadingModal()
    const fd = new FormData();
    
     
    
         fd.append('title', profile.title)
         fd.append('user_code', String(params.code))
         fd.append('sorting', profile.sorting)
         fd.append('type', 'Footer')
         fd.append('group_code', 'g_code')
         fd.append('link', '')
         fd.append('show_on_vcf', 'No')
         fd.append('show_on_profile', 'Yes')
         fd.append('link', 'Yes')
         fd.append('description', profile.infotext)
         let url = ServerUrl+'/group/item/add';
         axios.post(url, fd, config)
         .then(response =>{
         if(response.data.status === 200){
          SuccessModal(response.data.message)

          handleAllItems()
          var pht = document.getElementById(close)
          pht?.click()

               } else{
               ErrorModal('', response.data.message)
               }   
             })
             .catch((error)=>{
            ErrorModal('oops', error.message)
             }) 
  }
}

const handleUpdateGroup =(event: FormEvent<HTMLButtonElement>, close:string)=>{
  event.preventDefault();

  let error = {...errors}; 
  let formIsValid = true;

let msg ='This field is required';


if(!groupInfo.group_name){
  error.group_name=msg;
  formIsValid = false;
}
  
  
if(!formIsValid){
  setErrors(error) 
  }


  if(formIsValid) {
  
    LoadingModal()
    const fd = new FormData();
  
     
         fd.append('group_name', groupInfo.group_name)
         fd.append('code', groupInfo.group_code)
         fd.append('sorting', groupInfo.sorting)

         fd.append('show_profile', groupInfo.show_on_profile)
         
         let url = ServerUrl+'/group/update/'+groupInfo.group_code;
         axios.post(url, fd, config)
         .then(response =>{
         if(response.data.status === 200){
          SuccessModal(response.data.message)

          handleAllItems()
          setGroupInfo ({...groupInfo,
            title:'',
            sorting:'',
            group_code:'',
            group_name:'',
            group_title:'',
            show_on_profile:'Yes'
          })
          var pht = document.getElementById(close)
          pht?.click()

               } else{
               ErrorModal('', response.data.message)
               }   
             })
             .catch((error)=>{
            ErrorModal('oops', error.message)
             }) 
  }
}




const handleUpdateRating =(event: FormEvent<HTMLButtonElement>, close:string)=>{
  event.preventDefault();

  
    LoadingModal()
    const fd = new FormData();
  
     
         fd.append('allowRating', profile.allowRating)
         fd.append('rating_link', groupInfo.rating_link)

         let url = ServerUrl+'/client/update_google/'+params.code;
         axios.post(url, fd, config)
         .then(response =>{
         if(response.data.status === 200){
          SuccessModal(response.data.message)

          handleAllItems()
          var pht = document.getElementById(close)
          pht?.click()

               } else{
               ErrorModal('', response.data.message)
               }   
             })
             .catch((error)=>{
            ErrorModal('oops', error.message)
             }) 
  }


const handleUpdateField =(event: FormEvent<HTMLButtonElement>, close:string)=>{
  event.preventDefault();

  
    LoadingModal()
    const fd = new FormData();
  
     
         fd.append('field', 'allowExchange')
         fd.append('field_value', profile.allowExchange)
         let url = ServerUrl+'/client/update/filed/'+params.code;
         axios.post(url, fd, config)
         .then(response =>{
         if(response.data.status === 200){
          SuccessModal(response.data.message)

          handleAllItems()
          var pht = document.getElementById(close)
          pht?.click()

               } else{
               ErrorModal('', response.data.message)
               }   
             })
             .catch((error)=>{
            ErrorModal('oops', error.message)
             }) 
  }



const handleSubmitGroup =(event: FormEvent<HTMLButtonElement>, close:string)=>{
  event.preventDefault();

  let error = {...errors}; 
  let formIsValid = true;

let msg ='This field is required';


if(!profile.title){
  error.title=msg;
  formIsValid = false;
}
  
  
if(!formIsValid){
  setErrors(error) 
  }


  if(formIsValid) {
  
    LoadingModal()
    const fd = new FormData();
  
     
         fd.append('title', profile.title)
         fd.append('user_code', String(params.code))
         fd.append('sorting', profile.sorting)
         
         let url = ServerUrl+'/group/add';
         axios.post(url, fd, config)
         .then(response =>{
         if(response.data.status === 200){
          SuccessModal(response.data.message)

          handleAllItems()
          setProfile ({...profile,
            title:'', 
            sorting:''
          })
          var pht = document.getElementById(close)
          pht?.click()

               } else{
               ErrorModal('', response.data.message)
               }   
             })
             .catch((error)=>{
            ErrorModal('oops', error.message)
             }) 
  }
}


const openlink=(item:any)=>{
  if(item.link && item.link!==''){

    window.open(item.item_link+item.link, '_blank')
  }

}


const DeleteModal =(code:string, link:string)=>{

let url = ServerUrl+`/${link}/delete/`+code;
  Swal.fire({
    title: 'Are you really sure?',
    text: "This is very dangerous, you shouldn't do it!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    showLoaderOnConfirm: true,
  preConfirm: async() => {
    return await axios.get(url,  config).then(response=>{
     if(response.data.status!==200){
          throw new Error(response.statusText)
        }
        return response.data
      })
      .catch(error => {
        Swal.showValidationMessage(
          `Request failed: ${error}`
        )
      })
  },
  allowOutsideClick: () => !Swal.isLoading(),
    confirmButtonText: 'Yes, I am!'
  }).then((result:any) => {
    if (result.isConfirmed && result.value.status===200) {
     
       Swal.fire(
                  'Deleted!',
                  'Record has been deleted.',
                  'success'
                ) 

                handleAllItems()
    }
  })
}


const handleAllItems =()=>{
  fetchContent()
  fetchGroupItems()
  fetchGroup()
  fetchGroupFooter()
}

const handleTour =()=>{
  
  if (localStorage.getItem("rstr") === null && localStorage.getItem("rstr") !== String(user_code) ) {

    setTimeout(() => {
      setSteps({...steps, run: true });
      }, 4000);
    localStorage.setItem('rstr', String(user_code));
  }
}


  useEffect(()=>{
    handleAllItems()
    fetchItems()
    handleTour()
   }, []) 

   const handleJoyrideCallback = (data: CallBackProps) => {
    const { status, type } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setSteps({...steps, run: false });
    }
  };


   const handleClickStart = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();

    setSteps({...steps,
      run: true,
    });
  };


  return (<div  className= {mode==='dark'?'bg-dark text-white':''}>


<Header />


<Joyride
          steps={steps.steps}
          run={steps.run}
          callback={handleJoyrideCallback}
          continuous
        hideCloseButton
        scrollToFirstStep
        showProgress
        showSkipButton
          styles={{
            buttonNext: {
              // Styles for Next button
              backgroundColor:'#FF5511',
              color:'#fff'
            },
            buttonBack: {
            //Styles for Back Button
            height:30,
            backgroundColor:'#000',
            color:'#fff',
            borderRadius:5
            },
            options: {
              arrowColor: '#e3ffeb',
              backgroundColor: '#e3ffeb',
              overlayColor: 'rgba(79, 26, 0, 0.4)',
              primaryColor: '#000',
              textColor: '#004a14',
              width: 300,
              zIndex: 1000,
            }
          }}
        />

<header className="d-print-none">
<div className="container text-center text-lg-left">


<nav aria-label="breadcrumb">
  <ol className="breadcrumb">
    <li className="breadcrumb-item tour-13-dashboard"><a href="/dashboard"> <i className='fa fa-arrow-left'></i> Back</a></li>
    <li className="breadcrumb-item active" aria-current="page">Modify Account </li>
  </ol>
</nav>


<div className="py-3 clearfix">
<h1 className="site-title mb-0">{content.firstname +' '+content.middlename +' '+content.lastname}</h1>
<div className="site-nav">
<nav role="navigation">
<ul className="nav justify-content-center">

<li className="nav-item tour-12-share">
  <a className="nav-link" href="#!" title="Share"><i  className={mode==='dark'?'fa fa-share text-white':'fa fa-share'}></i><span className="menu-title sr-only">Share</span></a>
</li>



<li className="nav-item tour-11-theme">
<a className="nav-link"  href="#!" onClick={()=>setMode(mode==='light'?'dark':'light')} ><i className={mode==='dark'?'fa fa-sun text-white':'fa fa-sun'}></i><span className="menu-title sr-only">Setting</span></a>
</li>


</ul>
</nav>
</div>
</div>
</div>
</header>




  <div className="page-content" >
<div className="container">
<div className="cover shadow-lg " >
  <div className={`cover-bg p-3 p-lg-4  ${mode==='dark'?'bg-dark text-white':'text-white'}`} >
<div className="row">
<div className="col-lg-4 col-md-5">
<div className=" bg-whitess avatar p-1 flex-center">
  

<img className="img-fluid profile-img " id="viewLogo" onError={(e)=>{(e.target as HTMLImageElement).onerror = null; (e.target as HTMLImageElement).src =ImagesUrl+'/'+'no.png'}} src={ImagesUrl+'/clients/'+content.photo}   />

  
      
  <a href="#" data-bs-toggle="modal" data-bs-target="#modal-photo"  className=' tour-5-photo f-12' >
			<i className="fa fa-pencil-alt text-white-orange " > Edit</i></a>
      
  </div>

</div>
<div className="col-lg-8 col-md-7 text-center text-md-start">
<h2 className="h1 mt-2 aos-init aos-animate" data-aos="fade-left" data-aos-delay="0">{content.prefix+' '+content.firstname +' '+content.middlename +' '+content.lastname}</h2>
<p data-aos="fade-left" data-aos-delay="100" className="aos-init aos-animate"> {content.position}


<a href="#" data-bs-toggle="modal" data-bs-target="#modal-header" className=' ml-1 f-12' >
			<i className="fa fa-pencil-alt text-white tour-1-profile" > Edit</i></a>

      </p>
<div className="d-print-none aos-init aos-animate row" data-aos="fade-left" data-aos-delay="200">
  
<a href="#" data-bs-toggle="modal" data-bs-target="#modal-vcf"  className="btn btn-light text-primary shadow-sm me-1 mt-1 col-md-3 btn-block tour-2-vcf"    >
<i className='fa fa-user-plus'></i> Additional VCF Info</a>




<a className="btn btn-light text-primary shadow-sm mt-1 col-md-3 btn-block me-1 tour-3-email" href="#" data-bs-toggle="modal" data-bs-target="#modal-exchange"> <i className='fa fa-comment'></i> Exchange Settings</a>



<a className="btn btn-light text-primary shadow-sm mt-1 col-md-3 btn-block m-l-1 tour-4-rating" href="#" data-bs-toggle="modal" data-bs-target="#modal-google"> <img src={ImagesUrl+'/items/googlerate.png'} alt="" className='img-fluid img-30' width="30" height="30" /> Google Rating</a>


</div>
</div>
</div>
</div>

<div className="about-section pt-4 px-3 px-lg-4 mt-2 ">
<div className="row">


  <div className="col-md-12">
  <nav role="navigation">
<ul className="nav justify-content-center">


<a  data-bs-toggle="modal" className='tour-6-media' data-bs-target="#modal-media" href="#!" id='openmedia'>
  
  </a>


<li>
  <a className="nav-link m-l-1" onClick={()=>handleEditMedia({ title:'Phone Call', type:'Phone No.', photo:'phone.png'}, 'openmedia')} href="#!" title="Telephone">
  <img src={ImagesUrl+'/items/phone.png'} width="40" height="40"  />
  
  </a>
  </li>


<li><a className="nav-link m-l-1" onClick={()=>handleEditMedia({ title:'Whatsapp Message', type:'Whatsapp', photo:'whatsapp.png'}, 'openmedia')} href="#!" title="Whatsapp">
  <img src={ImagesUrl+'/items/whatsapp.png'} width="40" height="40"  /></a></li>


<li><a className="nav-link m-l-1"  onClick={()=>handleEditMedia({ title:'Email', type:'Email', photo:'mail.png'}, 'openmedia')} href="#!" title="Mail">
  <img src={ImagesUrl+'/items/mail.png'} width="40" height="40"  /></a></li>


 <li><a className="nav-link m-l-1" onClick={()=>handleEditMedia({ title:'Instagram', type:'Instagram', photo:'instagram.png'}, 'openmedia')} href="#!" title="Mail">
  <img src={ImagesUrl+'/items/instagram.png'} width="40" height="40"  /></a></li> 


</ul>
</nav>
  </div>
<div className="col-md-12" >
 <br/><br/>
<h2 className="h3 mb-3 mt-2">About Me</h2>
<p dangerouslySetInnerHTML={{__html:content.about}}></p>
</div>



</div>

<div className="row">
  <div className="col-md-12 d-flex justify-content-center">
<a href="#!" onClick={()=>setProfile({...profile, isSection:!profile.isSection})}  className=' ml-1 f-12 text-bold text-success h3 tour-7-section' > Add New Section  <i className="fa fa-plus-circle text-danger " > </i></a>
      </div>
      </div>


</div> 
{profile.isSection?
<div className="row p-3">

<div className="col-md-12">

<div className={`card p-1  mb-1 ${mode==='dark'?'bg-dark':''} `} >
<div className=" d-flex justify-content-center pointer p-2">

  <div className='ml-1 '>

  <a id='opengroup'  data-bs-toggle="modal" data-bs-target="#modal-group" href='#!' ></a>

<a  onClick={()=>handleOpenSorting('opengroup', group)}  href='#!' className="h6 mb-0 text-success text-bold">New Group <span>(e.g: Contact Info, Social media ...)</span> </a>
</div>
</div>
</div>
</div>

<div className="col-md-12">
  
<div className={`card p-1  mb-1 ${mode==='dark'?'bg-dark':''} `} >
<div className=" d-flex justify-content-center pointer p-2">


  <div className='ml-1 '>



  

  <a id='opentext' data-bs-toggle="modal" data-bs-target="#modal-text" href='#!' ></a>
<a  onClick={()=>handleOpenSorting('opentext', groupFooter)}  href='#!' className="h6 mb-0 text-success text-bold">New Text <span>(e.g: Opening Our, About Us ...)</span> </a>

</div>
</div>
</div></div>


</div>:[]}












<hr className="d-print-none" />

{group&&group.map((list:any, index:number)=>
<div className="work-experience-section px-3 px-lg-4" key={index} >

<h2 className="h3 mb-4">{list.title} <a href="#!" onClick={()=>handleEditGroup(list)} className=' ml-1   text-success' >   <i className="fa fa-edit tour-8-edit" > </i></a>  


<a href="#!" id='updateGroup' data-bs-toggle="modal" data-bs-target="#modal-group-update"   ></a> 


<a href="#!" onClick={()=>DeleteModal(list.code, 'group')}  className=' ml-1 tour-9-remove  text-danger' >   <i className="fa fa-trash " > </i></a> </h2>


<div className={`card p-1  mb-1 ${mode==='dark'?'bg-dark':''} `} onClick={()=>handleAddItem(list)} >

<div className=" d-flex align-items-center pointer">

<div className="square">
  <i className='fa fa-plus text-success'></i>
</div>

  <div className='ml-1 '>
<h6 className="h6 mb-0 tour-10-new">Add New Field to {list.title} </h6>
</div>
</div>
</div>



<div className="timeline">


{groupItems&&groupItems.filter((data:any)=>data.group_code===list.code).map((item:any, id:number)=>
<div className="timeline-card timeline-card-success card shadow-sm" key={id}>

<div className={`${mode==='dark'?'card p-1 bg-dark':'card p-1 '}`} >

<div className=" d-flex align-items-center  pointer" onClick={()=>openlink(item)} >

<img src={ImagesUrl+'/items/'+item.photo} width="37" height="37" onError={(e)=>{(e.target as HTMLImageElement).onerror = null; (e.target as HTMLImageElement).src =ImagesUrl+'/'+'no.png'}} />

  <div className='ml-1 '>
<h6 className="h6 mb-0">{item.title} </h6>

<label className={mode==='dark'?'text-white':'text-muted'}>{item.description}</label>

</div>


</div>


<div className='pull-right '>
<a href='#!'  onClick={()=>openlink(item)} className=' m-l-1   text-info' >   <i className="fa fa-eye " > </i></a> 


<a href="#!" data-bs-toggle="modal" data-bs-target="#modal-item-update" className=' m-l-1   text-warning' id='updateItem' >  </a>


 <a href="#!" className=' m-l-1   text-warning' onClick={()=>handleEditItem(item, 'updateItem')} >   <i className="fa fa-edit " > </i></a>


<a href="#" onClick={()=>DeleteModal(item.code, 'group/item')}  className=' m-l-1  text-danger' >   <i className="fa fa-trash " > </i></a>

</div>


</div>
</div>)}


</div><br/>
</div>)}





<br/>
<div className="page-break"></div>
<div className="education-section px-3 px-lg-4 pb-4">


{groupFooter&&groupFooter.map((item:any, id:number)=><div key={id}>
  <div className= {`${mode==='dark'?'card p-1 pointer mb-2 mt-1 bg-dark text-white':'card p-1 pointer mb-2 mt-1  '}`} >

<div className=" d-flex align-items-center pointer ">


  <div className='ml-1 '>
<h6 className="h6 mb-1">{item.title} 


<a href="#!" data-bs-toggle="modal" data-bs-target="#modal-update-text" className=' m-l-1   text-warning' id='updatetext' >  </a>

<a href="#!" className=' m-l-1   text-warning' onClick={()=>handleEditItem(item, 'updatetext')} >   <i className="fa fa-edit " > </i></a>



<a href="#!"  className=' ml-1   text-danger' onClick={()=>DeleteModal(item.code, 'group/item')} >   <i className="fa fa-trash " > </i></a></h6>



<p className=' text-muted'>{item.description}</p>
</div></div>


</div></div>)}




</div>


</div>


</div>
</div>




<div className="modal fade" id="modal-media"  aria-modal="true" role="dialog">
			<div className="modal-dialog" role="document">
				<div className= {mode==='dark'?'bg-dark text-white modal-content':'modal-content'}>
        <div className="modal-header">
        <h6 className="modal-title text-bold">Edit Quick Link {media.type}</h6>
        <button type="button" className="btn-close btn-sm" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
					<div className="modal-body ">



          <div className="row">


<div className="col-sm-12">

<div className="mb-3">
    <label className='mb-1'><img src={ImagesUrl+'/items/'+media.photo} width="30" height="30" onError={(e)=>{(e.target as HTMLImageElement).onerror = null; (e.target as HTMLImageElement).src =ImagesUrl+'/'+'no.png'}} /> {media.title}</label>

 

    <Select  
    
    options={
      media.list.concat({code:'', description:'', title:media.type, item_code:''}).map((list:any, id:number)=>{
           return {key:id, value:list.code, label:list.description, type:list.title,  item_code:list.item_code}
               })}
               styles={styles}
              className={errors.types?" form-control-errors ":" "} 
onChange={handleSelectMedia}  name="data" value={media.data} 

/>
<span className='text-danger'>{media.errorMessage}</span><br/>

<span>Empty value will hide the link on your profile page</span>
  </div>
 
</div>
</div>
	</div>

					<div className="modal-footer text-center">
		
          <button className='btn btn-secondary btn-sm' data-bs-dismiss="modal" aria-label="Close" id='close-media' type='button'>Close</button>
<button className='btn btn-success btn-sm' type='button' onClick={(e)=>handleUpdateMedia(e, 'close-media')} >Update </button>
					
					</div>
				</div>
			</div>
		</div>




    <div className="modal fade" id="modal-google"  aria-modal="true" role="dialog">
			<div className="modal-dialog" role="document">
				<div className= {mode==='dark'?'bg-dark text-white modal-content':'modal-content'}>
        <div className="modal-header">
        <h6 className="modal-title text-bold">Google Rating Settings</h6>
        <button type="button" className="btn-close btn-sm" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
					<div className="modal-body ">

          <div className="row">


<div className="col-md-12">



  <div className="">
 
 <label className="switch-md">
   <input type="checkbox" name='addContact' checked={profile.allowRating==='Yes'?true:false} onChange={()=>setProfile({...profile, allowExchange:profile.allowRating==='Yes'?'No':'Yes'})} />
   <span className="slider-md round"></span>
 </label>
 &nbsp;
 <label className="text-success text-bold">
 Enable Google Rating
 </label> 
 </div>



 <div className="mb-3 mt-3">
    <label>Google Rating Link</label>
    <input type="text" value={profile.rating_link} name="rating_link" className='form-control' placeholder='' onChange={handleChange} />
  </div>


 
</div>
</div>
	</div>

					<div className="modal-footer text-center">
		
          <button className='btn btn-secondary btn-sm' data-bs-dismiss="modal" aria-label="Close" id='close-google' type='button'>Close</button>
<button className='btn btn-success btn-sm' type='button' onClick={(e)=>handleSubmitInfo(e, 'close-google')} >Update </button>
					
					</div>
				</div>
			</div>
		</div>


<div className="modal fade" id="modal-exchange"  aria-modal="true" role="dialog">
			<div className="modal-dialog" role="document">
				<div className= {mode==='dark'?'bg-dark text-white modal-content':'modal-content'}>
        <div className="modal-header">
        <h6 className="modal-title text-bold">Exchange Contacts Destination Email</h6>
        <button type="button" className="btn-close btn-sm" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
					<div className="modal-body ">



          <div className="row">


<div className="col-sm-12">



  <div className="">
 
 <label className="switch-md">
   <input type="checkbox" name='addContact' checked={profile.allowExchange==='Yes'?true:false} onChange={()=>setProfile({...profile, allowExchange:profile.allowExchange==='Yes'?'No':'Yes'})} />
   <span className="slider-md round"></span>
 </label>
 &nbsp;
 <label className="text-success text-bold">
 Enable Exchange Contact
 </label> 
 </div>

 
</div>
</div>
	</div>

					<div className="modal-footer text-center">
		
          <button className='btn btn-secondary btn-sm' data-bs-dismiss="modal" aria-label="Close" id='close-exchange' type='button'>Close</button>
<button className='btn btn-success btn-sm' type='button' onClick={(e)=>handleUpdateField(e, 'close-exchange')} >Update </button>
					
					</div>
				</div>
			</div>
		</div>














<div className="modal fade" id="modal-update-text"  aria-modal="true" role="dialog">
			<div className="modal-dialog" role="document">
				<div className= {mode==='dark'?'bg-dark text-white modal-content':'modal-content'}>
        <div className="modal-header">
        <h6 className="modal-title text-bold">Edit Info Group {groupInfo.group_title}</h6>
        <button type="button" className="btn-close btn-sm" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
					<div className="modal-body ">



          <div className="row">


<div className="col-sm-12">

  <div className="mb-3">
    <label>Title</label>
    <input type="text" value={groupInfo.title} name="title" className={errors.title?"form-control form-control-errors ":"form-control "} placeholder='Opening hours, About Us etc' onChange={handleChangeItem} />
  </div>




  <div className="mb-3">
    <label>Text</label>
    <textarea name="description"  className={errors.description?"form-control form-control-errors ":"form-control "}  placeholder='Info Group Text' value={groupInfo.description} cols={10} rows={5} onChange={handleChangeAreaItem} >

    </textarea>


  </div>


  <div className="mb-3">
    <label>Display Order</label>
    
    <input type="number" value={groupInfo.sorting} name="sorting" className={errors.sorting?"form-control form-control-errors ":"form-control "} onChange={handleChange} />
  </div>


  <div className="">
 
 <label className="switch-md">
   <input type="checkbox" name='addContact' checked={groupInfo.show_on_profile==='Yes'?true:false} onChange={()=>setGroupInfo({...groupInfo, show_on_profile:groupInfo.show_on_profile==='Yes'?'No':'Yes'})} />
   <span className="slider-md round"></span>
 </label>
 &nbsp;
 <label className="text-success text-bold">
 Show On Profile Page
 </label> 
 </div>


</div>
</div>
	</div>

					<div className="modal-footer text-center">
		
          <button className='btn btn-secondary btn-sm' data-bs-dismiss="modal" aria-label="Close" id='close-update-text' type='button'>Close</button>
<button className='btn btn-success btn-sm' type='button' onClick={(e)=>handleUpdateItem(e, 'close-update-text')} >Update Changes</button>
					
					</div>
				</div>
			</div>
		</div>




<div className="modal fade" id="modal-text"  aria-modal="true" role="dialog">
			<div className="modal-dialog" role="document">
				<div className= {mode==='dark'?'bg-dark text-white modal-content':'modal-content'}>
        <div className="modal-header">
        <h6 className="modal-title text-bold">Add New Text</h6>
        <button type="button" className="btn-close btn-sm" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
					<div className="modal-body ">



          <div className="row">


<div className="col-sm-12">

  <div className="mb-3">
    <label>Title</label>
    <input type="text" value={profile.title} name="title" className={errors.title?"form-control form-control-errors ":"form-control "} placeholder='Opening hours, About Us etc' onChange={handleChange} />
  </div>




  <div className="mb-3">
    <label>Text</label>
    <textarea name="infotext"  className={errors.infotext?"form-control form-control-errors ":"form-control "}  placeholder='Info Group Text' value={profile.infotext} cols={10} rows={5} onChange={handleChangeArea} >

    </textarea>


  </div>


  <div className="mb-3">
    <label>Display Order</label>
    
    <input type="number" value={profile.sorting} name="sorting" className={errors.sorting?"form-control form-control-errors ":"form-control "} onChange={handleChange} />
  </div>

</div>
</div>
	</div>

					<div className="modal-footer text-center">
		
          <button className='btn btn-secondary btn-sm' data-bs-dismiss="modal" aria-label="Close" id='closetext' type='button'>Close</button>
<button className='btn btn-success btn-sm' type='button' onClick={(e)=>handleSubmitItem(e, 'closetext')} >Add Text</button>
					
					</div>
				</div>
			</div>
		</div>


<div className="modal fade" id="modal-photo"  aria-modal="true" role="dialog">
			<div className="modal-dialog" role="document">
				<div className= {mode==='dark'?'bg-dark text-white modal-content':'modal-content'}>
        <div className="modal-header">
        <h6 className="modal-title">Edit Profile Image</h6>
        <button type="button" className="btn-close btn-sm" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
					<div className="modal-body text-center">


        <img className="img-fluid profile-image " id="viewLogo" onError={(e)=>{(e.target as HTMLImageElement).onerror = null; (e.target as HTMLImageElement).src =ImagesUrl+'/'+'no.png'}} src={profile.passport}   />


<div className="row">
            <div className="col-md-12" ><br/>
              <div className="input-group">
							<strong>Select Image: </strong> &nbsp;
							<input type="file"  className='form-control'  id="logo" onChange={handleFile('logo')} accept="image/*"   name="logo" />
              
              </div>
              </div>
						</div>


					</div>
					<div className="modal-footer text-center">
		

          <button className='btn btn-secondary btn-sm' data-bs-dismiss="modal" aria-label="Close" id='closephoto' type='button'>Close</button>
<button className='btn btn-success btn-sm' type='button' onClick={handleSubmit} >Upload Image</button>
					
					</div>
				</div>
			</div>
		</div>










    <div className="modal fade" id="modal-item-update"  aria-modal="true" role="dialog">
			<div className="modal-dialog" role="document">
				<div className= {mode==='dark'?'bg-dark text-white modal-content':'modal-content'}>
        <div className="modal-header">
        <h6 className="modal-title text-bold"><img src={ImagesUrl+'/items/'+groupInfo.photo} width="37" height="37" onError={(e)=>{(e.target as HTMLImageElement).onerror = null; (e.target as HTMLImageElement).src =ImagesUrl+'/'+'no.png'}} /> Edit {groupInfo.group_title}</h6>
        <button type="button" className="btn-close btn-sm" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
					<div className="modal-body ">



          <div className="row">


<div className="col-sm-12">

  <div className="mb-3">
    <label>Title</label>
    <input type="text" value={groupInfo.title} name="title" className={errors.title?"form-control form-control-errors ":"form-control "} onChange={handleChangeItem} />
  </div>

  <div className="mb-3">
    <label>Link</label>
    
    <input type="text" value={groupInfo.link} name="link" className={"form-control "} onChange={handleChangeItem} />
  </div>

  <div className="mb-3">
    <label>Description</label>
    
    <input type="text" value={groupInfo.description} name="description" className={errors.description?"form-control form-control-errors ":"form-control "} onChange={handleChangeItem} />
  </div>


  <div className="mb-3">
    <label>Display Order</label>
    
    <input type="number" value={groupInfo.sorting} name="sorting" className={errors.sorting?"form-control form-control-errors ":"form-control "} onChange={handleChangeItem} />
  </div>


  <div className="">
 
 <label className="switch-md">
   <input type="checkbox" name='addContact' checked={groupInfo.show_on_profile==='Yes'?true:false} onChange={()=>setGroupInfo({...groupInfo, show_on_profile:groupInfo.show_on_profile==='Yes'?'No':'Yes'})} />
   <span className="slider-md round"></span>
 </label>
 &nbsp;
 <label className="text-success text-bold">
 Show On Profile Page
 </label> 
 </div>
<br/>

 <div className="">
 
 <label className="switch-md">
   <input type="checkbox" name='addContact' checked={groupInfo.show_on_vcf==='Yes'?true:false} onChange={()=>setGroupInfo({...groupInfo, show_on_vcf:groupInfo.show_on_vcf==='Yes'?'No':'Yes'})} />
   <span className="slider-md round"></span>
 </label>
 &nbsp;
 <label className="text-success text-bold">
 Save in a Contact File
 </label> 
 </div>

 
</div>
</div>
	</div>

					<div className="modal-footer text-center">
		
          <button className='btn btn-secondary btn-sm' data-bs-dismiss="modal" aria-label="Close" id='closeitem_update' type='button'>Close</button>
<button className='btn btn-success btn-sm' type='button' onClick={(e)=>handleUpdateItem(e, 'closeitem_update')} >Update Item</button>
					
					</div>
				</div>
			</div>
		</div>















    <div className="modal fade" id="modal-group-update"  aria-modal="true" role="dialog">
			<div className="modal-dialog" role="document">
				<div className= {mode==='dark'?'bg-dark text-white modal-content':'modal-content'}>
        <div className="modal-header">
        <h6 className="modal-title text-bold">Edit Group Name {groupInfo.group_title}</h6>
        <button type="button" className="btn-close btn-sm" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
					<div className="modal-body ">



          <div className="row">


<div className="col-sm-12">

  <div className="mb-3">
    <label>Title</label>
    <input type="text" value={groupInfo.group_name} name="group_name" className={errors.group_name?"form-control form-control-errors ":"form-control "} onChange={handleChangeItem} />
  </div>


  <div className="mb-3">
    <label>Display Order</label>
    
    <input type="number" value={groupInfo.sorting} name="sorting" className={errors.sorting?"form-control form-control-errors ":"form-control "} onChange={handleChangeItem} />
  </div>


  <div className="">
 
 <label className="switch-md">
   <input type="checkbox" name='addContact' checked={groupInfo.show_on_profile==='Yes'?true:false} onChange={()=>setGroupInfo({...groupInfo, show_on_profile:groupInfo.show_on_profile==='Yes'?'No':'Yes'})} />
   <span className="slider-md round"></span>
 </label>
 &nbsp;
 <label className="text-success text-bold">
 Show On Profile Page
 </label> 
 </div>

 
</div>
</div>
	</div>

					<div className="modal-footer text-center">
		
          <button className='btn btn-secondary btn-sm' data-bs-dismiss="modal" aria-label="Close" id='closegroup' type='button'>Close</button>
<button className='btn btn-success btn-sm' type='button' onClick={(e)=>handleUpdateGroup(e, 'closegroup')} >Update Group</button>
					
					</div>
				</div>
			</div>
		</div>


    <div className="modal fade" id="modal-group"  aria-modal="true" role="dialog">
			<div className="modal-dialog" role="document">
				<div className= {mode==='dark'?'bg-dark text-white modal-content':'modal-content'}>
        <div className="modal-header">
        <h6 className="modal-title text-bold">Add New Group</h6>
        <button type="button" className="btn-close btn-sm" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
					<div className="modal-body ">



          <div className="row">


<div className="col-sm-12">

  <div className="mb-3">
    <label>Title</label>
    <input type="text" value={profile.title} name="title" className={errors.title?"form-control form-control-errors ":"form-control "} onChange={handleChange} />
  </div>


  <div className="mb-3">
    <label>Display Order</label>
    
    <input type="number" value={profile.sorting} name="sorting" className={errors.sorting?"form-control form-control-errors ":"form-control "} onChange={handleChange} />
  </div>

 
</div>
</div>
	</div>

					<div className="modal-footer text-center">
		
          <button className='btn btn-secondary btn-sm' data-bs-dismiss="modal" aria-label="Close" id='closegroup' type='button'>Close</button>
<button className='btn btn-success btn-sm' type='button' onClick={(e)=>handleSubmitGroup(e, 'closegroup')} >Add Group</button>
					
					</div>
				</div>
			</div>
		</div>




    <div className="modal fade" id="modal-header"  aria-modal="true" role="dialog">
			<div className="modal-dialog" role="document">
				<div className= {mode==='dark'?'bg-dark text-white modal-content':'modal-content'}>
        <div className="modal-header">
        <h6 className="modal-title text-bold">Edit Profile Header</h6>
        <button type="button" className="btn-close btn-sm" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
					<div className="modal-body ">



          <div className="row">


<div className="col-sm-12">


  <div className="mb-3">
    <label>First Name</label>
    <input type="text" value={profile.firstname} name="firstname" className={errors.firstname?"form-control form-control-errors ":"form-control "} onChange={handleChange} />
  </div>


  <div className="mb-3">
    <label>Middle Name (Optional)</label>
    
    <input type="text" value={profile.middlename} name="middlename" className={errors.lastname?"form-control form-control-errors ":"form-control "} onChange={handleChange} />
  </div>


  <div className="mb-3">
    <label>Last Name</label>
    
    <input type="text" value={profile.lastname} name="lastname" className={errors.lastname?"form-control form-control-errors ":"form-control "} onChange={handleChange} />
  </div>

  <div className="mb-3">
    <label>Position / Company</label>
    <input type="text" value={profile.position} name="position" className="form-control " onChange={handleChange} />
  </div>



  <div className="mb-3">
    <label>About Me</label>
    <textarea name="about" className="form-control " value={profile.about} onChange={handleChangeArea}>
{profile.about}
      
      </textarea>
  </div>


</div>
</div>
	</div>



					<div className="modal-footer text-center">
		

          <button className='btn btn-secondary btn-sm' data-bs-dismiss="modal" aria-label="Close" id='closeHeaders' type='button'>Close</button>

<button className='btn btn-success btn-sm' type='button' onClick={(e)=>handleSubmitInfo(e, 'closeHeaders')} >Save Changes</button>
					
					</div>
				</div>
			</div>
		</div>




    <a  data-bs-toggle="modal" id='addItem' data-bs-target="#modal-group-item" href="#!" ></a>


    <div className="modal fade" id="modal-group-item"  aria-modal="true" role="dialog">
			<div className="modal-dialog" role="document">
				<div className= {mode==='dark'?'bg-dark text-white modal-content':'modal-content'}>
        <div className="modal-header">
        <h6 className="modal-title text-bold">Add Contact to Group {groupInfo.group_name}</h6>
        <button type="button" className="btn-close btn-sm" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
					<div className="modal-body ">



          <div className="row">


<div className="col-sm-12">


  <div className="mb-3">
    <label className='text-bold'>Type</label>


    <Select  
    
    options={
     items&&items.map((list:any, id:number)=>{
          return {key:id, value:list.code, label:list.title, placeholder:list.placeholder}
              })}
              styles={styles}
              className={errors.types?" form-control-errors ":" "} 
onChange={handleSelectItem}  name="types" value={groupInfo.types} 

/>

  </div>


  <div className="mb-3">
    <label className='text-bold'>Title</label>
    
    <input type="text" value={groupInfo.title} name="title" className={errors.title?"form-control form-control-errors ":"form-control "}  onChange={handleChangeItem} />
  </div>


  <div className="mb-3">
    <label className='text-bold'>Link</label>
    <input type="text" value={groupInfo.link} name="link" className="form-control " onChange={handleChangeItem} placeholder={groupInfo.types.placeholder} />
  </div>


 
  <div className="mb-3">
    <label className='text-bold'>Description</label>
    <input type="text" value={groupInfo.description} name="description"  className={errors.description?"form-control form-control-errors ":"form-control "}  onChange={(e)=>handleChangeDescription(e, groupInfo.types.label)} />
  </div>


  



  



  <div className="mb-3">
    <label className='text-bold'>Display Order</label>
    <input type="number" value={groupInfo.sorting} name="sorting" className="form-control " onChange={handleChangeItem} />
  </div>





   <div className="">
 
<label className="switch-md">
  <input type="checkbox" name='addContact' checked={groupInfo.show_on_profile==='Yes'?true:false} onChange={()=>setGroupInfo({...groupInfo, show_on_profile:groupInfo.show_on_profile==='Yes'?'No':'Yes'})} />
  <span className="slider-md round"></span>
</label>
&nbsp;
<label className="text-success text-bold">
Show On Profile Page
</label> 
</div> 

<br/>

<div className="">
 
 <label className="switch-md">
   <input type="checkbox" name='addContact' checked={groupInfo.show_on_vcf==='Yes'?true:false} onChange={()=>setGroupInfo({...groupInfo, show_on_vcf:groupInfo.show_on_vcf==='Yes'?'No':'Yes'})} />
   <span className="slider-md round"></span>
 </label>
 &nbsp;
 <label className="text-success text-bold">
 Show On VCF File
 </label> 
 </div>



</div>
</div>
	</div>



					<div className="modal-footer text-center">
		

          <button className='btn btn-secondary btn-sm' data-bs-dismiss="modal" aria-label="Close" id='closegitem' type='button'>Close</button>
<button className='btn btn-success btn-sm' type='button' onClick={(e)=>handleSubmitGroupItem(e, 'closegitem')} >Save Changes</button>
					
					</div>
				</div>
			</div>
		</div>


    <div className="modal fade"  id="modal-vcf"  aria-modal="true" role="dialog">
			<div className="modal-dialog" role="document">
				<div  className= {mode==='dark'?'bg-dark text-white modal-content':'modal-content'}>
        <div className="modal-header">
        <h6 className="modal-title text-bold">Edit VCF File Settings</h6>
        <button type="button" className="btn-close btn-sm" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
					<div className="modal-body ">



          <div className="row">


<div className="col-sm-12">


  <div className="mb-3">
    <label className='text-bold'>Prefix (Optional)</label>
    Apostle
    <Select  options={[ 
{value:'Apostle', label:'Apostle'},
{value:'Mr.', label:'Mr.'},
{value:'Mrs.', label:'Mrs.'}, 
{value:'Ms.', label:'Ms.'}, 
 {value:'Dr', label:'Dr.'}, 
 {value:'General', label:'General'}, 
{value:'Esquire', label:'Esquire'},
{value:'Honorable', label:'Hon.'}, 
{value:'Mallam', label:'Mallam'},
{value:'Pst.', label:'Pst.'},
{value:'Junior', label:'Jr.'}, 
{value:'Messrs.', label:'Messrs.'},
{value:'Mmes.', label:'Mmes.'}, 
{value:'Msgr.', label:'Msgr.'},
{value:'Prof.', label:'Prof.'}, 
{value:'Rev.', label:'Rev.'},
{value:'Rt. Hon.', label:'Rt. Hon.'}, 
{value:'Sr.', label:'Sr.'}, 
{value:'St.', label:'St.'}
           ]}   
           styles={styles}
onChange={handleSelect}  name="prefix" value={{value:profile.prefix, label:profile.prefix}} 

/>

  </div>


  <div className="mb-3">
    <label className='text-bold'>Company</label>
    
    <input type="text" value={profile.company} name="company" className={"form-control "} onChange={handleChange} />
  </div>

  <div className="mb-3">
    <label className='text-bold'>Role</label>
    <input type="text" value={profile.role} name="role" className="form-control " onChange={handleChange} />
  </div>


  <div className="mb-3">
    <label className='text-bold'>Job Title</label>
    <input type="text" value={profile.job_title} name="job_title" className="form-control " onChange={handleChange} />
  </div>



  <div className="mb-3">
    <label className='text-bold'>Country</label>
    <input type="text" value={profile.country} name="country" className="form-control " onChange={handleChange} />
  </div>



  <div className="mb-3">
    <label className='text-bold'>Region</label>
    <input type="text" value={profile.region} name="region" className="form-control " onChange={handleChange} />
  </div>


  <div className="mb-3">
    <label className='text-bold'>City</label>
    <input type="text" value={profile.city} name="city" className="form-control " onChange={handleChange} />
  </div>


  <div className="mb-3">
    <label className='text-bold'>Street</label>
    <input type="text" value={profile.street} name="street" className="form-control " onChange={handleChange} />
  </div>


  <div className="mb-3">
    <label className='text-bold'>Address</label>
    <textarea name="address" className="form-control " value={profile.address} onChange={handleChangeArea}>
{profile.address}
      
      </textarea>
  </div>


  <div className="mb-3">
    <label className='text-bold'>More Info</label>
    <input type="text" value={profile.more_info} name="more_info" className="form-control " onChange={handleChange} />
  </div>




   <div className="">
 
<label className="switch-md">
  <input type="checkbox" name='addContact' checked={profile.addContact} onChange={()=>setProfile({...profile, addContact:!profile.addContact})} />
  <span className="slider-md round"></span>
</label>
&nbsp;
<label className="text-success text-bold">
Enable Add Contact
</label> 
</div> 


</div>
</div>
	</div>



					<div className="modal-footer text-center">
		

          <button className='btn btn-secondary btn-sm' data-bs-dismiss="modal" aria-label="Close" id='closevcf' type='button'>Close</button>
<button className='btn btn-success btn-sm' type='button' onClick={(e)=>handleSubmitInfo(e, 'closevcf')} >Save Changes</button>
					
					</div>
				</div>
			</div>
		</div>




    



 <Footer />

    </div>
  )
}

export default EditHomepage