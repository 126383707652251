import React, {useEffect} from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import EditHomepage from './pages/EditHomepage';
import Dashboard from './pages/Dashboard';
import VisitDetails from './pages/VisitDetails';
import ExchangeContact from './pages/ExchangeContact';
import Error404 from './pages/error404';
import RequestCard from './pages/RequestCard';
import Notification from './pages/Notification';
import ResetPassword from './users/Reset_Password';

const ProtectedView =()=>{

    
        return ( <>
       
       <Routes>

       <Route  path="/dashboard" element={<Dashboard />} /> 
          <Route  path="/exchange_contact" element={<ExchangeContact />} /> 
          <Route  path="/visit_details" element={<VisitDetails />} /> 
          <Route  path="/request_card" element={<RequestCard />} /> 
          <Route  path="/notifications" element={<Notification />} /> 
          <Route  path="/user/edit/:code" element={<EditHomepage />} /> 
         {/*  <Route  path="*" element={<Error404 />} /> */}
        </Routes>
      </>
    );
  
}
export default ProtectedView;
