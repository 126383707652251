import { useEffect, useState, FormEvent, ChangeEvent } from 'react'
import Header from '../components/Header';
import Footer from '../components/Footer';

import Joyride, { CallBackProps, STATUS, Step }  from 'react-joyride'
import axios from 'axios'
import { FrontUrl, ImagesUrl, ServerUrl, user_code,  config } from '../components/includes';
import QRCode from 'qrcode'
import { ErrorModal, LoadingModal, SuccessModal } from '../components/notify';

const Dashboard = () => {

  const [mode, setMode] = useState ('light')
  var today = new Date().toISOString().slice(0,10);
  const [content, setContent] = useState([]as any)
  const [profile, setProfile] = useState ({
    qrcode:'',
    qrcard:'',
    ref_link:'https://card.ekiconet.digital/create_account?ref='+user_code,
    email:'',
    password:'',
    secure_text:'',
    secure_message:'Delete My Account'

   })

   const [statistics, setStatistics] = useState ({
    visit:0,
    lastVisit:'',
    exchange:0,
    lastExchange:'',
    register:'',
    expiry_date:'',
    daysLeft:20

   })

   const [errors, setErrors] = useState({
    secure_text:'',
    password:'',
    email:''
});

   const copyToClipboard =()=>{
         
    const el = document.createElement('textarea');
    el.value = profile.ref_link
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
SuccessModal('Link copied')

}

   const [steps, setSteps] = useState({
    run: false,
    steps: [{
      target: '.tour-1-profile',
      title:'View Profile',
      content: 'Click to view how your profile looks like to visitors.',
      disableBeacon: true
    },
    {
      target: '.tour-2-edit',
      title:'Edit Profile',
      content: 'Click here to modify your profile details',
    },
    {
      target: '.tour-3-svg',
      content: 'Click here to generate and download your profile link as svg format',   
      title:'Download as SVG'
    },
    { 
      target: '.tour-4-png',
      content: 'lick here to generate and download your profile link as png format',   
      title:'Download as PNG'
    },
    {
      target: '.tour-5-vcard',
      content: 'Click here for direct access to your profile details offline.',   
      title:'Offline Contact'
    },
    {
      target: '.tour-6-card',
      content: 'Click here to request for Eki digital card, you can also check status of your card',   
      title:'Request for Eki Card'
    },
    {
      target: '.tour-7-view',
      content: 'Total number of visitors that view your profile',   
      title:'Total View'
    },
    {
      target: '.tour-8-exchange',
      content: 'Total number of visitors that request your contact to be shared',   
      title:'Total Shared'
    },
    {
      target: '.tour-9-expiry',
      content: 'The date this account will no longer be visible to visitors until you upgrade ',   
      title:'Account Expiry'
    }
    ],
    placement: 'bottom',
  });

   const downloadSVG =()=>{
    var opts:any = {
      errorCorrectionLevel: 'H',
      type: 'string',
      Default:'svg'
    }
    let link = FrontUrl+'/profile/'+user_code
    QRCode.toString(link, opts, function (err, string) {

  if (err) throw err
  const url = window.URL.createObjectURL(new Blob([string]));
  
  const link = document.createElement('a');
      link.href = url;
      link.download = user_code+'.svg'
      link.click();

})

  

   }


   const downloadImage =(link:string)=>{
    var opts:any = {
      errorCorrectionLevel: 'H',
      type: 'image/jpeg',
      quality: 0.3,
      margin: 1,
    }
    //let link = FrontUrl+'/profile/'+user_code
    QRCode.toDataURL(link, opts, function (err, url) {          
      const link = document.createElement('a');
      link.href = url;
      link.download = user_code+'.png'
      link.click();
      })

   }


  const generateQR = async () => {
    try {

      let link = FrontUrl+'/profile/'+user_code
      let cardlink = ServerUrl+'/client/download/'+user_code
      let qr = await QRCode.toDataURL(link)
      let qrcard = await QRCode.toDataURL(cardlink)
  setProfile({...profile, qrcode:qr, qrcard:qrcard})
  
    } catch (err) {
      console.error(err)
    }
  }
  

  const fetchStat = async()=>{
    let url = ServerUrl+'/clients/statistics/'+user_code
      await  axios.get(url,  config).then((result:any)=>{
        if(result.data.data&&result.data.data.length!==0){
            try{
        setStatistics({
          ...statistics,
          ...result.data.data[0]
        })
    }catch(e){

    }
        }
        })
        .catch((error)=>{
       
        })
      }

  const fetchContent = async()=>{
    let url = ServerUrl+'/clients/display/user/'+user_code
      await  axios.get(url,  config).then((result:any)=>{
        if(result.data.data&&result.data.data.length!==0){
            try{
        setContent(result.data.data)
    }catch(e){

    }
        }else{
            window.open('/logout', '_self');
        }
        })
        .catch((error)=>{
       
        })
      }




	const handleDeleteAccount =(event: FormEvent<HTMLButtonElement>)=>{
		event.preventDefault();
	
		let error = {...errors}; 
		let formIsValid = true;
	
	let msg ='This field is required';
	

  let email = profile.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i); 
  if(!email){
    error.email =msg;
    formIsValid = false;
  } 

  if(!profile.password){
		error.password=msg;
		formIsValid = false;
	} 
  
  if(!profile.secure_text){
		error.secure_text=msg;
		formIsValid = false;
	} 


  if(profile.secure_text !== profile.secure_message){
		error.secure_text='Message do not match';
		formIsValid = false;
	} 


	if(!formIsValid){
		setErrors(error) 
		}


		
		if(formIsValid) {
		
			LoadingModal()
			const fd = new FormData();
		

				   Object.entries(profile).forEach(([key, value]) => {
              fd.append(key, String(value));
          });


				   let url = ServerUrl+'/client/account/delete/'+user_code;
				   axios.post(url, fd, config)
				   .then(response =>{
					 if(response.data.status === 200){
					  SuccessModal(response.data.message)

            var pht = document.getElementById('closeDelete')
            pht?.click()
            setTimeout(() => {
              window.location.href='/'
            }, 3000);
							   } else{
								 ErrorModal('', response.data.message)
							   }   
						   })
						   .catch((error)=>{
							ErrorModal('oops', error.message)
						   }) 
		}
}



      function datediff(first:string, second:string) {        

        const diffInMs:any = +(new Date(second)) - +(new Date(first))
        const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
        return diffInDays;
    }

    
   

    const handleTour =()=>{
  
      if (localStorage.getItem("rstrd") === null && localStorage.getItem("rstrd") !== String(user_code) ) {
    
        setTimeout(() => {
          setSteps({...steps, run: true });
          }, 4000);
        localStorage.setItem('rstrd', String(user_code));
      }
    
      
    }

    const handleChange =(event:ChangeEvent<HTMLInputElement>)=>{
      let {name, value} = event.target;	

      setProfile({...profile, [name]:value});
     setErrors({...errors, [name]:''});
   }


    const handleJoyrideCallback = (data: CallBackProps) => {
      const { status, type } = data;
      const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];
  
      if (finishedStatuses.includes(status)) {
        setSteps({...steps, run: false });
      }
    };
  
  
     const handleClickStart = (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault();
  
      setSteps({...steps,
        run: true,
      });
    };

      useEffect(()=>{
        fetchContent()
        generateQR()
        fetchStat()
        handleTour()
       }, []) 

  return (
<>
<Header />




<Joyride
          steps={steps.steps}
          run={steps.run}
          callback={handleJoyrideCallback}
          continuous
        hideCloseButton
        scrollToFirstStep
        showProgress
        showSkipButton
          styles={{
            buttonNext: {
              // Styles for Next button
              backgroundColor:'#FF5511',
              color:'#fff'
            },
            buttonBack: {
            //Styles for Back Button
            height:30,
            backgroundColor:'#000',
            color:'#fff',
            borderRadius:5
            },
            options: {
              arrowColor: '#e3ffeb',
              backgroundColor: '#e3ffeb',
              overlayColor: 'rgba(79, 26, 0, 0.4)',
              primaryColor: '#000',
              textColor: '#004a14',
              width: 300,
              zIndex: 1000,
            }
          }}
        />


  <div className="page-content mt-4">
<div className="container">
<h5>Welcome {content.username}</h5>

<nav aria-label="breadcrumb">
  <ol className="breadcrumb">
    <li className="breadcrumb-item"><a href="#!"><i className='fa fa-desktop'></i></a></li>
    <li className="breadcrumb-item active" aria-current="page">Eki Co-net Account</li>
  </ol>
</nav>


<div className="affiliate-referral-link text-center">

<h3>Your Unique Referral Link</h3>
<span className='pointer' onClick={copyToClipboard}>{profile.ref_link}</span>

</div>



  <div className="row mt-4">

<div className=" col-sm-6 col-md-6 col-xl-3">
<div className="card custom-card">
    <div className="card-body user-card text-center">
        <div className="main-img-user avatar-lg online text-center">


<img className="img-fluid icon-image " id="viewLogo" onError={(e)=>{(e.target as HTMLImageElement).onerror = null; (e.target as HTMLImageElement).src =ImagesUrl+'/'+'no.png'}} src={ImagesUrl+'/clients/'+content.photo}   />

        </div>

<div className="mt-2">
<h5 className="mb-1">{content.prefix+' '+content.firstname +' '+content.lastname}</h5>
<p className="mb-1 tx-inverse">{content.position} ||  {content.company}</p>
    <span className="text-muted"><i className="fa fa-check-circle text-success mr-1"></i>Active</span>
    </div>
    
            <a href={FrontUrl+'/profile/'+content.username+'/'+user_code} target="_blank" className="btn btn-sm btn-primary mt-3 mr-1 tour-1-profile"><i className='fa fa-eye'></i> View Profile</a>&nbsp;

            <a href={FrontUrl+'/user/edit/'+user_code} className="btn btn-sm btn-warning mt-3 tour-2-edit"><i className='fa fa-edit'></i> Edit Profile</a>

    

    </div>
</div>
</div>




<div className=" col-sm-6 col-md-6 col-xl-3">
<div className="card custom-card">
    <div className="card-body user-card text-center">
        <div className="main-img-user avatar-lg online text-center">


<img className="img-fluid icon-image " id="viewLogo" src={profile.qrcode} 
  />

        </div>

<div className="mt-2">
<h5 className="mb-1">Online Link</h5>
<p className="mb-1 tx-inverse">Your Connect Profile Link</p>
    <span className="text-muted"><i className="fa fa-user-circle text-success mr-1"></i></span>
    </div>
   
    <button type='button' onClick={downloadSVG} className="btn btn-sm btn-primary mt-3 tour-3-svg"><i className='fa fa-download'></i> .SVG</button>&nbsp;

            <button type='button' onClick={()=>downloadImage(FrontUrl+'/profile/'+user_code)} className="btn btn-sm btn-primary mt-3 tour-4-png"><i className='fa fa-download'></i> .PNG</button>
<div className="mb-1"></div>
    

    </div>
</div>
</div>


<div className=" col-sm-6 col-md-6 col-xl-3">
<div className="card custom-card">
    <div className="card-body user-card text-center">
        <div className="main-img-user avatar-lg online text-center">


<img className="img-fluid icon-image " id="viewLogo" src={profile.qrcard} 
  />

        </div>

<div className="mt-2">
<h5 className="mb-1">Offline Contact</h5>
<p className="mb-1 tx-inverse">Contact Vcard</p>
    <span className="text-muted"><i className="fa fa-minus-circle text-danger mr-1"></i></span>
    </div>
            <button onClick={()=>downloadImage(ServerUrl+'/client/download/'+user_code)} type='button' className="btn btn-sm btn-primary mt-3 mr-1 tour-5-vcard"><i className='fa fa-download'></i> Generate</button>&nbsp;

<div className="mb-1"></div>
    

    </div>
</div>
</div>





<div className=" col-sm-6 col-md-6 col-xl-3">
<div className="card custom-card">
    <div className="card-body user-card text-center">
        <div className="main-img-user avatar-lg online text-center">


        <img className="img-fluid icon-image " id="viewLogo" onError={(e)=>{(e.target as HTMLImageElement).onerror = null; (e.target as HTMLImageElement).src =ImagesUrl+'/'+'no.png'}} src={ImagesUrl+'/about/card.png'}   />


        </div>

<div className="mt-2">
<h5 className="mb-1">Eki Card</h5>
<p className="mb-1 tx-inverse">A New digital card</p>
    <span className="text-muted"><i className="fa fa-key text-success mr-1"></i></span>
    </div>
   
            <a href='/request_card' className="btn btn-sm btn-primary mt-3 mr-1 tour-6-card"><i className='fa fa-money-bill'></i> Request For Card</a>&nbsp;

<div className="mb-1"></div>
    

    </div>
</div>
</div>

                                </div>


 


<section className="section dashboard">
      <div className="row">

        <div className="col-lg-12">
          <div className="row">

            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 ">
              <div className="card custom-card pointer" onClick={()=>window.open('/visit_details', '_self')}>



                <div className="card-body">
                  <h5 className="card-title">Total View</h5>
                  <span className="text-muted small pt-2 ps-1">Total number of visits</span>

                  <div className="card-item-icon  d-flex">

<div className="eye-wrapper">
<i className="fa fa-eye text-primary fa-3x" ></i></div>
<h1 className='text-primary ml-1 tour-7-view'>{statistics.visit}</h1>
</div>

<br/>
                  <div className="d-flex align-items-center">
                  &nbsp; <span>Last visit:</span> &nbsp;<span className="text-success small pt-1 fw-bold">{statistics.lastVisit} </span>
                    
                  </div>
                </div>




              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 ">
              <div className="card custom-card pointer" onClick={()=>window.open('/exchange_contact', '_self')}>



                <div className="card-body">
                  <h5 className="card-title">EXCHANGED CONTACTS</h5>
                  <span className="text-muted small pt-2 ps-1 ">Total exchanged contact</span>

                  <div className="card-item-icon  d-flex">

<div className="eye-wrapper">
<i className="fa fa-envelope text-primary fa-3x" ></i></div>
<h1 className='text-primary ml-1 tour-8-exchange'>{statistics.exchange}</h1>
</div>

<br/>
                  <div className="d-flex align-items-center">
                  &nbsp; <span>Last shared:</span> &nbsp;<span className="text-success small pt-1 fw-bold">{statistics.lastExchange} </span>
                    
                  </div>
                </div>




              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-4 ">
              <div className="card custom-card ">



                <div className="card-body">
                  <h5 className="card-title">ACCOUNT EXPIRY</h5>
                  <span className="text-muted small pt-2 ps-1">Date Registered: {statistics.register}</span>

                  <div className="card-item-icon  d-flex">

<div className="eye-wrapper">
<i className="fa fa-calendar text-primary fa-3x" ></i></div>
<h1 className='text-primary ml-1 tour-9-expiry'>{statistics.expiry_date}</h1>
</div>

<br/>
                  <div className="d-flex justify-content-between">
                    <div>
                   <span>Days Left:</span> &nbsp;<span className="text-danger small pt-1 fw-bold">{
                  
                  datediff(today, content.expiry_date)} </span>
                    </div>

                    <a  className="text-danger  pt-1 fw-bold pointer"  data-bs-toggle="modal" data-bs-target="#modal-delete" href='#!' > Delete Account</a>


                  </div>


                  
                </div>




              </div>
            </div>

          
           

          </div>
        </div>

      </div>
    </section>
</div>
</div>




<div className="modal fade" id="modal-delete"  aria-modal="true" role="dialog">
			<div className="modal-dialog" role="document">
				<div className= {mode==='dark'?'bg-dark text-white modal-content':'modal-content'}>
        <div className="modal-header">
        <h6 className="modal-title text-bold">Are you sure you want to do this?</h6>
        <button type="button" id='closeDelete' className="btn-close btn-sm" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
					<div className="modal-body ">


<div className="alert alert-danger">

Once you delete your account, there is no going back. Please be certain.
</div>

          <div className="row">
<p>We will immediately delete all of your contacts, along with all of your images, messages, card request, your visitors, and exchange contacts</p>


          <div className="mb-3">
    <label className='text-bold'>Your email address:</label>

    <input type="text"  name="email" required autoComplete='off' role={'presentation'} value={profile.email} onChange={handleChange} className={errors.email  ? 'form-control form-control-danger form-control-lg' : 'form-control form-control-lg'} /> 
                      {errors.email&&
                        <div className="col-form-label text-danger">{errors.email&&errors.email} </div>}

  </div>


  <div className="mb-3">
    <label className='text-bold'>To verify, type <i className='text-danger'>{profile.secure_message}</i> below:</label>

    <input type="text"  name="secure_text" required autoComplete='off' role={'presentation'} value={profile.secure_text} onChange={handleChange} className={errors.secure_text  ? 'form-control form-control-danger form-control-lg' : 'form-control form-control-lg'} /> 
                      {errors.secure_text&&
                        <div className="col-form-label text-danger">{errors.secure_text&&errors.secure_text} </div>}
  </div>


  <div className="mb-3">
    <label className='text-bold'>Confirm your Password:</label>

    <input type="password"  name="password" required autoComplete='off' role={'presentation'} value={profile.password} onChange={handleChange} className={errors.password  ? 'form-control form-control-danger form-control-lg' : 'form-control form-control-lg'} /> 
                      {errors.password&&
                        <div className="col-form-label text-danger">{errors.password&&errors.password} </div>}
  </div>

</div>
	</div>
<div className="col-md-12">
					<div className="modal-footer">
		
<button className='btn btn-danger btn-sm btn-block' type='button' onClick={handleDeleteAccount} ><i className='fa fa-trash'></i> Delete This Account Now</button>
					
					</div>
          </div>
				</div>
			</div>
		</div>



<Footer />
</>
  )
}

export default Dashboard