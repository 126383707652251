import { useEffect, useState, FormEvent, ChangeEvent } from 'react'
import Footer from '../components/Footer'
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { FrontUrl, ImagesUrl, ServerUrl,  config, configDownload } from '../components/includes';
import QRCode from 'qrcode'
import { ErrorModal, LoadingModal, SuccessModal } from '../components/notify'

const Homepage = () => {
  let params = useParams()

  const [errors, setErrors] = useState({
    image:'',
    fullname:'',
    email:'',
    telephone:'',
    message:'',
    puzzle:'',
});


const [mode, setMode] = useState ('light')

const [media, setMedia] = useState ({
 phone:'',
 whatsapp:'',
 instagram:'',
 email:''
 })


   const [profile, setProfile] = useState ({
    qrcode:'',
    telephone:'',
    email:'',
    message:'',
    fullname:'',
    num1:'',
        num2:'',
        answer:'',
        puzzle:'',
        user_code:params.code
   })

   const handleChange =(event:ChangeEvent<HTMLInputElement>)=>{
    let {name, value} = event.target;	

    //telephone
    if(name==='telephone'){
      value = value.replace(/[^0-9]/g, "")
    }

    setProfile({...profile, [name]:value});
   setErrors({...errors, [name]:''});
 }


 const handleChangeArea =(event:ChangeEvent<HTMLTextAreaElement>)=>{
  let {name, value} = event.target;	
  setProfile({...profile, [name]:value});
 setErrors({...errors, [name]:''});
}


const getMediaLink=(type:string)=>{
  let itm = groupItems&&groupItems.filter((list:any)=>list.item_title===type)
  let ans = itm.filter((list:any)=>list.is_default==='Yes')
  return ans.length!==0?ans[0].link:''

}

const fetchMedia =()=>{

  setMedia({
   
    phone:getMediaLink('Phone'),
    email:getMediaLink('Email'),
    whatsapp:getMediaLink('Whatsapp'),
    instagram:getMediaLink('Instagram'),
  })
}


   const handleReset=()=>{
    var num1 = Math.floor(Math.random() * 9)
    var num2 = Math.floor(Math.random() * 9)
    var answer = num1+num2
   
       setProfile({
        ...profile,
        telephone:'',
        email:'',
        message:'',
        fullname:'',
        num1:String(num1), 
        num2:String(num2), 
        answer:answer.toString(),
        puzzle:''
       })
   }


  const [content, setContent] = useState({
    
	
prefix:'',	
company:'',	
role:'',	
job_title:'',	
country:'',	
region:'',	
city:'',	
street:'',	
address:'',	
more_info:'',	
about:'',	
photo:'',	
firstname:'',	
lastname:'',	
middlename:'',	
username:'',	
position:'',	
email_address:'',	
expiry_date:'',	
allowExchange:'',	
staff_create:'',
rating_link:''

  })
  const [groupItems, setGroupItems] = useState([]as any)
  const [group, setGroup] = useState([]as any)
  const [groupFooter, setGroupFooter] = useState([]as any)

  const fetchGroup = async()=>{
    let url = ServerUrl+'/groups/display/user/'+params.code
      await  axios.get(url,  config).then((result:any)=>{
        if(result.data.data&&result.data.data.length!==0){
          setGroup(result.data.data)
        }
        })
        .catch((error)=>{
       
        })
      }


      const countView = async()=>{
        let url = ServerUrl+'/client/view/'+params.code
          await  axios.get(url,  config)
          }


      const downloadCard = async()=>{
        let url = ServerUrl+'/client/download/'+params.code
            await  axios.get(url, configDownload).then((response:any)=>{
              const url = window.URL.createObjectURL(new Blob([response.data]));
                
                  const link = document.createElement('a');
                  link.href = url;
                  link.download = params.code+'.vcf'
                  link.click();
                  })
                  .catch((error)=>{
                   // ErrorModal('error', error.message)
                  })
          }


      const fetchGroupFooter = async()=>{
        let url = ServerUrl+'/groups/footer/user/'+params.code
          await  axios.get(url,  config).then((result:any)=>{
            if(result.data.data&&result.data.data.length!==0){
              setGroupFooter(result.data.data)
            }
            })
            .catch((error)=>{
           
            })
          }

     
 
// With async/await
const generateQR = async () => {
  try {
//puzzle
    var num1 = Math.floor(Math.random() * 9)
            var num2 = Math.floor(Math.random() * 9)
            var answer = num1+num2



    let link = FrontUrl+'/profile/'+params.code
    let qr = await QRCode.toDataURL(link)
setProfile({...profile, qrcode:qr, num1:String(num1), num2:String(num2), answer:answer.toString()})

  } catch (err) {
    console.error(err)
  }
}


      const fetchGroupItems = async()=>{
        let url = ServerUrl+'/groups/item/display/all/'+params.code
          await  axios.get(url,  config).then((result:any)=>{
            if(result.data.data&&result.data.data.length!==0){
              setGroupItems(result.data.data)

            }
            })
            .catch((error)=>{
           
            })
          }


  const fetchContent = async()=>{
    let url = ServerUrl+'/clients/display/user/'+params.code
    if(params.username!==undefined && params.username!==null && params.username!==''){
     url = ServerUrl+'/clients/display/username/'+params.username+'/'+params.code
    }
    
      await  axios.get(url,  config).then((result:any)=>{
       
        if(result.data.data&&result.data.data.length!==0){
    
        setContent(result.data.data)
  
        }else{
           window.open('/404', '_self');
        }
        })
        .catch((error)=>{
       
        })
      }

    
      const handleSubmitInfo =(event: FormEvent<HTMLButtonElement>, closeid:string)=>{
        event.preventDefault();
      
        let error = {...errors}; 
        let formIsValid = true;
      
      let msg ='This field is required';
      
      let email = profile.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i); 
            if(!email){
                formIsValid = false;
                error.email ='Please enter valid email address';
            }

      if(!profile.fullname){
        error.fullname=msg;
        formIsValid = false;
      } 
    
      if(!profile.telephone){
        error.telephone=msg;
        formIsValid = false;
      } 
      

      if(!profile.message){
        error.message=msg;
        formIsValid = false;
      } 


      if(!profile.puzzle){
        error.puzzle=msg;
        formIsValid = false;
      }else if(Number(profile.puzzle) !== Number(profile.answer)){
        formIsValid = false;
        error.puzzle ='Answer provided to puzzle is not correct';
      
    } 


    
      if(!formIsValid){
        setErrors(error) 
        }
        
        
        if(formIsValid) {
        
          LoadingModal()
          const fd = new FormData();
        
          Object.entries(profile).forEach(([key, value]) => {
            fd.append(key, String(value));
        });

               let url = ServerUrl+'/client/send_mail';
               axios.post(url, fd, config)
               .then(response =>{
               if(response.data.status === 200){
                SuccessModal(response.data.message)
    
                handleAllItems()
                
                var pht = document.getElementById(closeid)
                pht?.click()
    
    
    
                     } else{
                     ErrorModal('', response.data.message)
                     }   
                   })
                   .catch((error)=>{
                  ErrorModal('oops', error.message)
                   }) 
        }
    
    }


const openlink=(item:any)=>{
  if(item.link && item.link!==''){

    window.open(item.item_link+item.link, '_blank')
  }

}


const handleAllItems =()=>{
  fetchContent()
  fetchGroupItems()
  fetchGroup()
  fetchGroupFooter()
 
}

  useEffect(()=>{
    handleAllItems()
    generateQR()
    countView()
   }, []) 


   useEffect(()=>{
    fetchMedia()
   }, [groupItems]) 

   
  return (<div  className= {mode==='dark'?'bg-dark text-white':''}>

<Helmet>
          <title>{content.firstname +' '+content.middlename +' '+content.lastname}</title>
        <meta name="description" content={content.about} />
       
    
        <meta property="og:title" content={content.firstname +' '+content.lastname}/> 
        <meta property="og:description" content={content.about} /> 
        <meta property="og:image" content={ImagesUrl+'/clients/'+content.photo} />  

        <meta property="og:url" content={FrontUrl+'/profile/'+params.code}/> 
      
<meta property="og:type"          content="website" />

    
<link rel="icon" href={ImagesUrl+'/clients/'+content.photo} />
        <meta name="twitter:title" content={content.firstname +' '+content.lastname} /> 
        <meta name="twitter:description" content={content.about}/> 
        <meta name="twitter:image" content={ImagesUrl+'/clients/'+content.photo} />  
        <meta name="twitter:url" content={FrontUrl+'/profile/'+params.code} /> 

    </Helmet>


<header className="d-print-none" >
<div className="container text-center text-lg-left" data-bs-theme={mode}>
<div className="py-3 clearfix">


<h1 className="site-title mb-0">{content.firstname +' '+content.middlename +' '+content.lastname}</h1>


<div className="site-nav">
<nav role="navigation">
<ul className="nav justify-content-center">

<li className="nav-item ">
  <a className="nav-link"  data-bs-toggle="modal" data-bs-target="#modal-qrcode" href="#!" title="Share"><i  className={mode==='dark'?'fa fa-share text-white':'fa fa-share'}></i><span className="menu-title sr-only">Share</span></a>
</li>




<li className="nav-item">
  <a className="nav-link"  href="#!" onClick={()=>setMode(mode==='light'?'dark':'light')} ><i className={mode==='dark'?'fa fa-sun text-white':'fa fa-sun'}></i><span className="menu-title sr-only">Setting</span></a>
</li>


</ul>
</nav>
</div>
</div>
</div>
</header>




  <div className="page-content " >
<div className="container">
<div className="cover shadow-lg " data-bs-theme={mode}>
<div className={`cover-bg p-3 p-lg-4  ${mode==='dark'?'bg-dark text-white':'text-white'}`} >
<div className="row">
<div className="col-lg-4 col-md-5">
<div className=" bg-whitess  avatar p-1 flex-center">
  

<img className="img-fluid profile-img" id="viewLogo" onError={(e)=>{(e.target as HTMLImageElement).onerror = null; (e.target as HTMLImageElement).src =ImagesUrl+'/'+'no.png'}} src={ImagesUrl+'/clients/'+content.photo}   />

  
      
      
  </div>

</div>
<div className="col-lg-8 col-md-7 text-center text-md-start">
<h2 className="h1 mt-2 aos-init aos-animate" data-bs-theme={mode} data-aos="fade-left" data-aos-delay="0">{content.prefix+' '+content.firstname +' '+content.middlename+' '+content.lastname}</h2>
<p data-aos="fade-left" data-aos-delay="100" className="aos-init aos-animate"> {content.position}



      </p>
<div className="d-print-none aos-init aos-animate row" data-aos="fade-left" data-aos-delay="200">
  
<a href={ServerUrl+'/client/download/'+params.code}  className="btn btn-light text-primary shadow-sm mt-1 me-1 col-md-3 btn-block"    >
<i className='fa fa-user-plus'></i> Add Contact</a>



<a className="btn btn-light text-primary shadow-sm  mt-1 me-1 col-md-3  btn-block" href="#" data-bs-toggle="modal" data-bs-target="#modal-exchange"> <i className='fa fa-comment'></i> Exchange Contact </a>

<a className="btn btn-light text-primary shadow-sm col-md-3 btn-block mt-1 m-l-1" href={content.rating_link}  target='_blank'> <img src={ImagesUrl+'/items/googlerate.png'} alt="" className='img-fluid img-30' width="30" height="30" /> Google Rating</a>

</div>
</div>
</div>
</div>

<div className="about-section pt-4 px-3 px-lg-4 mt-2 ">
<div className="row">


  
<div className="col-md-12">
  <nav role="navigation">

<ul className="nav justify-content-center ">
{media.phone!==''?
<li>
  <a className="nav-link m-l-1" target='_blank' href={'tel:'+media.phone} title="Telephone">
  <img src={ImagesUrl+'/items/phone.png'} width="40" height="40"  />
  
  </a>
  </li>:[]}

  {media.whatsapp!==''?
<li><a className="nav-link m-l-1 " target='_blank' href={'https://wa.me/'+media.whatsapp} title="Whatsapp">
  <img src={ImagesUrl+'/items/whatsapp.png'} width="40" height="40"  /></a></li>:[]}

  {media.email!==''?
<li><a className="nav-link m-l-1" target='_blank'  href={'mailto:'+media.email} title="Mail">
  <img src={ImagesUrl+'/items/mail.png'} width="40" height="40"  /></a></li>:[]}

 {media.instagram!==''?
<li><a className="nav-link m-l-1" target='_blank' href={'https://instagram.com/'+media.instagram} title="Instagram">
  <img src={ImagesUrl+'/items/instagram.png'} width="40" height="40"  /></a></li>:[]} 


</ul>
</nav>
  </div>

<div className="col-md-12" data-bs-theme={mode}>
<br/><br/>
<h2 className="h3 mb-3 mt-2" data-bs-theme={mode}>About Me</h2>
<p dangerouslySetInnerHTML={{__html:content.about}} ></p>
</div>



</div>



</div>













<hr className="d-print-none" />

{group&&group.map((list:any, index:number)=>
<div className="work-experience-section px-3 px-lg-4 " key={index} data-bs-theme={mode}>

<h2 className="h3 mb-4">{list.title}  </h2>


<div className="timeline" data-bs-theme={mode}>


{groupItems&&groupItems.filter((data:any)=>data.group_code===list.code).map((item:any, id:number)=>
<div className="timeline-card timeline-card-success card shadow-sm " key={id}>
<div className={`${mode==='dark'?'card p-1 bg-dark':'card p-1 '}`} >

<div className=" d-flex align-items-center  pointer" onClick={()=>openlink(item)} >

<img src={ImagesUrl+'/items/'+item.photo} width="37" height="37" onError={(e)=>{(e.target as HTMLImageElement).onerror = null; (e.target as HTMLImageElement).src =ImagesUrl+'/'+'no.png'}} />

  <div className='ml-1 '>
<h6 className="h6 mb-0">{item.title} </h6>
<label className={mode==='dark'?'text-white':'text-muted'}>{item.description}</label>
</div>


</div>





</div>
</div>)}


</div><br/>
</div>)}





<br/>
<div className="page-break"></div>
<div className="education-section px-3 px-lg-4 pb-4 " data-bs-theme={mode}>


{groupFooter&&groupFooter.map((item:any, id:number)=><>
<div className= {`${mode==='dark'?'card p-1 pointer mb-2 mt-1 bg-dark text-white':'card p-1 pointer mb-2 mt-1  '}`} key={id}>

<div className=" d-flex align-items-center pointer ">


  <div className='ml-1 '>
<h6 className="h6 mb-1">{item.title} 
</h6>

<p className={mode==='dark'?'text-white':'text-muted'}>{item.description}</p>
</div></div>


</div></>)}




</div>


</div>


</div>
</div>





<div className="modal fade" id="modal-exchange"  aria-modal="true" role="dialog">
			<div className="modal-dialog" role="document">
				<div className="modal-content">
        <div className="modal-header">
        <h6 className="modal-title text-bold">Exchange Contacts With</h6>
        <button type="button" className="btn-close btn-sm" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
					<div className="modal-body ">



          <div className="row">


<div className="col-sm-12">

<div className="mb-3">
    <label>Name</label>
    <input type="text" value={profile.fullname} name="fullname" className={errors.fullname?"form-control form-control-errors ":"form-control "}  onChange={handleChange} />
  </div>



  <div className="mb-3">
    <label>Email</label>
    <input type="email" value={profile.email} name="email" className={errors.email?"form-control form-control-errors ":"form-control "}  onChange={handleChange} />
  </div>



  <div className="mb-3">
    <label>Telephone</label>
    <input type="text" value={profile.telephone} name="telephone" className={errors.telephone?"form-control form-control-errors ":"form-control "}  onChange={handleChange} />
  </div>


  <div className="mb-3">
    <label>Message</label>
    <textarea name="message"  className={errors.message?"form-control form-control-errors ":"form-control "}   value={profile.message} cols={10} rows={5} onChange={handleChangeArea} >

    </textarea>


  </div>



  <div className="mb-3">
    <label>Puzzle</label>
    
  <input type="text" id="puzzle"  className={errors.puzzle?"form-control form-control-errors ":"form-control "}  name="puzzle" role={'presentation'}  autoComplete="off" value={profile.puzzle} placeholder={profile.num1 + ' + '+ profile.num2 + '  =?' } onChange={handleChange} />
  </div>

  

 
</div>
</div>
	</div>

					<div className="modal-footer text-center">
		
          <button className='btn btn-secondary btn-sm' data-bs-dismiss="modal" aria-label="Close" id='close-exchange' type='button'>Close</button>
<button className='btn btn-success btn-sm' type='button' onClick={(e)=>handleSubmitInfo(e, 'close-exchange')}  > <i className='fa fa-envelope'></i> Send Mail </button>
					
					</div>
				</div>
			</div>
		</div>















    <div className="modal fade" id="modal-qrcode"  aria-modal="true" role="dialog">
			<div className="modal-dialog" role="document">
				<div className="modal-content">
        <div className="modal-header">
       
        <button type="button" className="btn-close btn-sm" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
					<div className="modal-body ">



          <div className="row">


<div className="col-sm-12 text-center">


<img src={profile.qrcode} className="qrcode"    />



 
</div>
</div>
	</div>

  <div className="modal-footer text-center">
		


    <div className=" text-center" >


    <a className="m-r-2" target="_blank" rel="nofollow noopener" href={"https://www.instagram.com/sharer/sharer.php?u="+FrontUrl+'/profile/'+params.code}>
        <img src={ImagesUrl+'/items/instagram.png'} width="50" height="50"  /></a>


      <a className="m-r-2" target="_blank" rel="nofollow noopener" href={"https://www.facebook.com/sharer/sharer.php?u="+FrontUrl+'/profile/'+params.code}>
        <img src={ImagesUrl+'/items/facebook.png'} width="50" height="50"  /></a>


        <a className="m-r-2" target="_blank" rel="nofollow noopener" href={"https://wa.me/?text="+FrontUrl+'/profile/'+params.code}>
        <img src={ImagesUrl+'/items/whatsapp.png'} width="50" height="50"  /></a>



        <a className="m-r-2" target="_blank" rel="nofollow noopener" href={"https://www.linkedin.com/sharing/share-offsite/?url="+FrontUrl+'/profile/'+params.code}>
        <img src={ImagesUrl+'/items/linkedin.png'} width="50" height="50"  /></a>


        <a className="m-r-2" target="_blank" rel="nofollow noopener" href={"https://telegram.me/share/url?url="+FrontUrl+'/profile/'+params.code}>
        <img src={ImagesUrl+'/items/telegram.png'} width="50" height="50"  /></a>


        <a className="m-r-2" target="_blank" rel="nofollow noopener" href={"http://www.twitter.com/intent/tweet?text="+FrontUrl+'/profile/'+params.code}>
        <img src={ImagesUrl+'/items/x.png'} width="50" height="50"  /></a>


    </div>
  
  </div>
				</div>
			</div>
		</div>





    



 <Footer />

    </div>
  )
}

export default Homepage