import React from 'react';

import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Homepage from './pages/Homepage';
import Login from './users/Login';
import Logout from './users/Logout';
import ProtectedView from './protected_router';
import Forget from './users/Forget_Password';
import AddUser from './users/AddUser';
import Error404 from './pages/error404';
import AccountVerify from './users/account_verify';
import ResetPassword from './users/Reset_Password';

function App() {

  return ( 
        <Router>
          <Routes>

          <Route  path="/" element={<Login />} /> 
          <Route  path="/404" element={<Error404 />} /> 
          <Route  path="/create_account" element={<AddUser />} />

          <Route  path="/account_verify" element={<AccountVerify />} />
          <Route  path="/logout" element={<Logout />} /> 
          <Route  path="/forgot_password" element={<Forget />} /> 
          <Route  path="/profile/:code" element={<Homepage />} /> 
          <Route  path="/profile/:username/:code" element={<Homepage />} /> 
          <Route  path="/reset_password" element={<ResetPassword />} /> 
         </Routes>

         <ProtectedView  />

        </Router> )

}

export default App;
