import { useEffect, useState, FormEvent, ChangeEvent, useCallback } from 'react'
import Header from '../components/Header';
import Footer from '../components/Footer';

import { usePaystackPayment } from 'react-paystack';
import Select, { components } from 'react-select'
import Swal from 'sweetalert2'
import axios from 'axios'
import { FrontUrl, ImagesUrl, ServerUrl, user_code,  config, FormatNumber, styles, currency, PSKLIVE, PSKTEST, generateCardNo } from '../components/includes';
import QRCode from 'qrcode'
import { ErrorModal, LoadingModal, SuccessModal, closeModal } from '../components/notify'
import { timeSince } from '../components/GlobalFunction';

const RequestCard = () => {
  const [cardCode, setCardCode] = useState('c'+Math.random().toString(36).substring(2,9))


  const [card, setCard] = useState({
    card_price:0,
    bank_details:'',
    default_payment:''
  })

  const [client, setClient] = useState({
    client_code:user_code,
    code:cardCode,
  location_price:0,
  card_price:0,
  total:0,
  payment_status:'',
  serial_no:generateCardNo(15),
  card_status:'',
  is_new:'Yes',
    fullname:'',
    locations:'' as any,
    address:'',
    position:'',
    passport:'',
    isLogo:false,
    filePath:'',
    image:'',
    photo:'',
    comment:''

  })
  const [errors, setErrors] = useState({
    fullname:'',
    location:'',
    address:'',
    position:'',
    successMessage:'',
    errorMessage:'',
    image:''
  });
  var txRef = (new Date()).getTime().toString();
  const [content, setContent] = useState([]as any)
  const [location, setLocation] = useState([]as any)
  const [profile, setProfile] = useState([]as any)

  var amount = Number(client.total) * 100

  //var amount = (Number(client.total)+ (0.015 * Number(client.total))+100) * 100

const paystackConfig = {
  reference: txRef,
  email: profile&&profile.email_address,
  amount: amount,
  publicKey: PSKLIVE
};

const steps = [{title: 'Income Head'}, {title: 'Expenses Head'}]
const [activeItem, setActiveItem] = useState(1);
    
    const handleOnClickStepper = (step:number) => {
        setActiveItem(step);        
        
    }
    const handleOnClickNext = useCallback(() => {
        setActiveItem(activeItem + 1);
    }, [activeItem])
    

    const handleOnClickBack = useCallback(() => {
        setActiveItem(activeItem - 1);
       
    }, [activeItem])


  const handlePaystackPayment = usePaystackPayment(paystackConfig);

  const fetchLocation = async()=>{
    let url = ServerUrl+'/location/display/all'
      await  axios.get(url,  config).then((result:any)=>{
        
        if(result.data.data&&result.data.data.length!==0){
          setLocation(result.data.data.filter(((item:any)=>item.is_active==='Yes')))
        }
        })
      }
      interface PaymentRef {
        message: string;
        redirecturl: string;
        reference: number | string;
        status: string;
        trans: number;
        transaction: number;
        trxref: number;
      }
      const onSuccess = (ref?: any) => {
  
        /*  message: "Approved"
       redirecturl: "?trxref=1662880169434&reference=1662880169434"
       reference: "1662880169434"
       status: "success"
       trans: "2094024185"
       transaction: "2094024185"
       trxref: "1662880169434" */
       
         //handleSubmitPayment(ref, 'Paystack');

handlePayment(ref)
        };

        const onClose = () => {
          // implementation for  whatever you want to do when the Paystack dialog closed.
          //console.log('closed')
        }

     
        const fetchProfile = async()=>{
          let url = ServerUrl+'/clients/display/user/'+user_code
            await  axios.get(url,  config).then((result:any)=>{
      
              if(result.data.data&&result.data.data.length!==0){
                setProfile(result.data.data)
              }
              })
              .catch((error)=>{
             
              })
            }

  const fetchContent = async()=>{
    LoadingModal()
    let url = ServerUrl+'/card/display/client/'+user_code
      await  axios.get(url,  config).then((result:any)=>{
        if(result.data.data&&result.data.data.length!==0){
          setContent(result.data.data)
        }
        })
        .catch((error)=>{
       
        }).finally(()=>{
          closeModal()
        })
      }

      const setLogo =()=>{
	 
        var doc = document.getElementById('photo')
        doc?.click()
        
        setClient({...client, isLogo:false, passport:'',  image:'', filePath:'', })
	}

      const handleSelect = (option:any, action:any)=>{

        let price = Number(option.price)>0?Number(option.price):0
      let total = Number(card.card_price) + price


        setClient({...client, [action.name]:option, location_price:option.price, card_price:card.card_price, total:total, address:''});
          setErrors({...errors, [action.name]:''})
           }
    

           const handleChange =(event:ChangeEvent<HTMLInputElement>)=>{
            let {name, value} = event.target;	
    
            setClient({...client, [name]:value});
           setErrors({...errors, [name]:''});
         }



      const  handleFile = function(fieldName:any){
          return function(newValue:any){                 
         readURL(newValue);
         }
        } 


        const readURL = (input:any)=>{

          let displayMessage = '';
          
          const PreviewImage = input.target.name;
          const inputElement = document.getElementById('logo') as HTMLInputElement
          const fileName = inputElement.value
          
          var idxDot = fileName.lastIndexOf(".") + 1;
          var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
          
          if (extFile==="jpg" || extFile==="jpeg" || extFile==="png" || extFile==="JPG" || extFile==="JPEG" || extFile==="PNG" ){
              //TO DO    
              
               var FileSize = input.target.files[0].size / 1024 / 1024; // in MB
          if (FileSize > 3) {
              displayMessage = ' File uploaded is larger than maximum size Allow (3 mb).';
              inputElement.value = '';
           
              setClient({...client, filePath:'', image:''})
          
          } else {
              if (input.target.files && input.target.files[0]) { 
                
                //Check if input has files.
                  var reader = new FileReader();//Initialize FileReader.
                  reader.onload = function (e:any) {

            

                  //Check if input has files.
                  let Id = Math.random().toString(36).substring(2, 9)
                   let fileUrl = Id+'.png'
          
                   setClient({...client,
                      filePath:fileUrl, 
                      passport:e.target.result, 
                      isLogo:true,
                      image:input.target.files[0]})
                    setErrors({...errors, image:''}); 
          
                  }
                  reader.readAsDataURL(input.target.files[0]);
                  
              }
          }
          }else{
          displayMessage = 'Only JPEG|PNG|JPG  file format are allowed with maximum of 3 mb'
          inputElement.value = '';
          setClient({...client, filePath:'', image:''})
          }   
          
          if(displayMessage.length > 0){
         ErrorModal("", displayMessage)
          }
          }
  

          const filterLocation=(code:string, field:string)=>{

            let rs = location&&location.filter((item:any)=>item.code===code)

            let ans = rs.length!==0?rs[0][field]:''
            return ans
          }

const handleComplete =(row:any)=>{

  setClient({...client,
    ...row,
   card_price:card.card_price,
    locations:{value:row.location, label:filterLocation(row.location, 'title'), price:filterLocation(row.location, 'price') },
    total: Number(row.card_price) + Number(filterLocation(row.location, 'price'))

  })

 
  var pht = document.getElementById('openrequest')
      pht?.click() 

}


          const handlePayment =(ref:any)=>{
            LoadingModal()
         const fd = new FormData();
       							
         
                fd.append('user_code', String(user_code))
                fd.append('email_address', profile.email_address)
                fd.append('amount', String(client.total))
                fd.append('status', 'Paid')
                fd.append('transaction_id', String(ref.transaction))
                fd.append('tx_ref', String(ref.trxref))
                fd.append('payload', JSON.stringify(ref, null, 2))
                fd.append('card_code', client.code)

           let url = ServerUrl+'/card/payment';
          axios.post(url, fd, config)
          .then(response =>{
            if(response.data.status === 200){
      
      //close modal here
      var pht = document.getElementById('closeModal')
      pht?.click() 
      fetchContent()
      setErrors({...errors, successMessage:response.data.message})

                      } else{
      
      setErrors({...errors, errorMessage:response.data.message})
                      }   
                  })
                  .catch((error)=>{
                   ErrorModal('oops', error.message)
                  }).finally(()=>{
                    closeModal()
                  }) 
  
        }


const handleCardRequest =()=>{

  let card = content&&content.filter((item:any)=>item.payment_status==='Pending')

  if(card.length!==0){
setErrors({...errors, errorMessage:'You have a pending transactions, kindly make payment before initiating another'})
  }else{
    var pht = document.getElementById('openrequest')
      pht?.click() 
  }
}
   const handleSubmit =(event: FormEvent<HTMLButtonElement>, modal:string)=>{
            event.preventDefault();
        
            let error = {...errors}; 
            let formIsValid = true;
        
        let msg ='This field is required';
        
        if(!client.fullname){
            error.fullname ='Please enter your fullname';
            formIsValid = false;
        } 
      
      
        if(!client.address){
          error.address ='Please enter your address';
          formIsValid = false;
      } 
      
        
      
      
      if(client.locations.label===''){
        error.location='Please select location';
        formIsValid = false;
      }
      
      
      
      if(!client.position){
      error.position ='Please enter your position';
      formIsValid = false;
      }
      
      
      
      
        if(!formIsValid){
        setErrors(error) 
        }
        
        
        if(formIsValid) {
        

          
         
            LoadingModal()
         const fd = new FormData();
            Object.entries(client).forEach(([key, value]) => {
                    fd.append(key, String(value));
                });

                 
       fd.append('location', client.locations.value);
        if(client.image){
					fd.append('photo', client.image)
				   }
               
           let url = ServerUrl+'/card/request';
          axios.post(url, fd, config)
          .then(response =>{
            if(response.data.status === 200){
       
          
      setErrors({...errors, successMessage:'Request successfully placed'})

      var pht = document.getElementById(modal)
          pht?.click() 

      fetchContent()
                      } else{
      
      setErrors({...errors, errorMessage:response.data.message})
                      }   
                  })
                  .catch((error)=>{
                   ErrorModal('oops', error.message)
                  }).finally(()=>{
                    closeModal()
                  })  
            }
        
        }

        
        const fetchSettings = async()=>{
          let url = ServerUrl+'/company/profile'
            await  axios.get(url,  config).then((result:any)=>{
              if(result.data.data&&result.data.data.length!==0){
              
                setCard({
                  card_price:Number(result.data.data.card_amount), 
                    default_payment:result.data.data.default_payment,
                    bank_details:result.data.data.bank_details 
                  })
              
              }
              })
              .catch((error)=>{
               //ErrorModal('oops', error.message)
              })
            }


      useEffect(()=>{
        fetchContent()
        fetchSettings()
        fetchLocation()
        fetchProfile()
       }, []) 

  return (
<>
<Header />




<div className="page-content mt-4">
<div className="container">
<h5>Request Digital Card</h5>

<nav aria-label="breadcrumb">
  <ol className="breadcrumb">
  <li className="breadcrumb-item"><a href="/dashboard"><i className='fa fa-desktop'></i></a></li>
    <li className="breadcrumb-item active" aria-current="page">Eki digital card</li>
  </ol>
</nav>


<div className="row mt-4">
			



{errors.successMessage!==''?
				  <div className="alert alert-success alert-dismissible fade show" role="alert">
          {errors.successMessage}
            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
          </div>:''}

			 {errors.errorMessage!==''?
				  <div className="alert alert-danger alert-dismissible fade show" role="alert">
          {errors.errorMessage}
            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
          </div>:''}



<div className="alert alert-primary alert-dismissible fade show" role="alert">
Our Eki Co-net Smart Business Cards, packed with powerful features to transform your networking experience
                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
              </div>



              <div className="row py-5">
  <div className="col-md-6">


<a data-bs-toggle="modal"  id='openrequest' data-bs-target="#fullscreenModal"  >

</a>


    <button type='button' className='btn btn-success btn-block' onClick={handleCardRequest}> <i className='fa fa-key'></i> Apply for new Card</button>
  </div>
</div>

{content.length!==0?
<div className="card">
            <div className="card-body">
              <h5 className="card-title">All Cards</h5>

              <ol className="list-group ">


                {content&&content.map((list:any, index:number)=>
                <li key={index} className="list-group-item d-flex justify-content-between align-items-start p-4   border-start border-end  border-4">
                  <div className="ms-2 me-auto">
                    <div className="fw-bold text-brand">{list.fullname}</div>
                   <i>SN:{list.serial_no}</i>
                  </div>


                {list.payment_status==='Pending'?  <div className="ms-2 me-auto">
                   <button type='button' onClick={()=>handleComplete(list)} className='btn btn-primary btn-sm'> <i className='fa fa-clock'></i> Make Payment</button>
                  </div>:[]}


                  <div className="">
                  <span className={`badge rounded-pill ${list.card_status==='Pending'?'bg-danger':list.card_status==='Processing'?'bg-primary':'bg-success'}`} >{list.card_status}</span>


                  <br />
                   Since { timeSince(new Date(list.created_at))}
                  </div>

                  
                </li>)}
              
              </ol>

            </div>
          </div>:[]}
	</div>



 






<div className="modal fade" data-bs-backdrop="static" id="fullscreenModal" tabIndex={-1} aria-modal="true" role="dialog" >
                <div className="modal-dialog modal-fullscreen">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title text-primary"> <i className='fa fa-money-bill'></i>  Eki Digital Card Request Form</h5>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" id='closeModal'></button>
                    </div>
                    <div className="modal-body">


                   



                    <h5 className="card-title text-success mb-1"> <i className="fa fa-check text-success"></i> Eki digital card takes upto 5 working days to be delivered</h5>


<h5 className="card-title text-success"><i className="fa fa-check text-success mb-1"></i> Delivery fees are charge based on your selected location</h5>


<h5 className="card-title text-success mb-1"><i className="fa fa-check text-success"></i> Request are processed upon successful payment</h5>


<h5 className="card-title text-success mb-1"><i className="fa fa-check text-success"></i> Please follow the on-screen instructions to complete the payment securely</h5>


If you have any questions or encounter issues, feel free to reach out. We will surely get back to you as soon as possible.

{activeItem===1?
                    <form action="" className='border-2 border-primary border p-3 mt-3 mb-3'>

        <div className="row mt-4">
        <div className="form-group row mb-3">
				
									
						
        <div className=" col-sm-4 justify-content-start  ">
        

        {client.photo!==''?
							<img className="img-100 p-image mb-1" id="viewLogo" onError={(e)=>{(e.target as HTMLImageElement).onerror = null; (e.target as HTMLImageElement).src =ImagesUrl+'/'+'no.png'}} src={ImagesUrl+'/cards/'+client.photo}   /> : <img className="img-100 p-image mb-1 " id="viewLogo" onError={(e)=>{(e.target as HTMLImageElement).onerror = null; (e.target as HTMLImageElement).src =ImagesUrl+'/'+'no.png'}} src={client.passport}   />}



{client.isLogo?<button type="button" onClick={setLogo} className="btn btn-danger btn-round mt-4 mb-2" > Remove File</button>:''}
<br/>
        <label className="btn btn-warning btn-round" htmlFor="logo" > Select  Company Logo </label>


            <input id="logo" onChange={handleFile('logo')} accept="image/*"   className="form-control  d-none" name="logo" type="file"  /> &nbsp;&nbsp;
                </div>
          </div>




        <div className="col-md-6 mb-1">
                      <label  className="form-label text-bold">Preferred Name for the Card <span className='text-danger'>*</span></label>

                      <input type="text"  name="fullname" required autoComplete='off' role={'presentation'} value={client.fullname} onChange={handleChange} className={errors.fullname  ? 'form-control form-control-danger form-control-lg' : 'form-control form-control-lg'} /> 
                      {errors.fullname&&
                        <div className="col-form-label text-danger">{errors.fullname} </div>}

                        <i>Let us know the name you'd like to be embedded on the card.</i>
                    </div>




                    <div className="col-md-6 mb-1">
                      <label  className="form-label text-bold"> Position Held in the Company <span className='text-danger'>*</span></label>

                      <input type="text"  name="position" required autoComplete='off' role={'presentation'} value={client.position} onChange={handleChange} className={errors.position ? 'form-control form-control-danger form-control-lg' : 'form-control form-control-lg'} /> 
                      {errors.position&&
                        <div className="col-form-label text-danger">{errors.position} </div>}

                        <i>Specify your current position or title within the company.</i>
                    </div>

                    <div className="col-md-6 mb-1">
                      <label  className="form-label text-bold"> Preferred Delivery Location <span className='text-danger'>*</span></label>

                     
                      
                  <Select  
                  
                  options={
                    location&&location.map((list:any, id:number)=>{
                         return {key:id, value:list.code, label:list.title, price:list.price}
                             })}
                    
styles={styles}
onChange={handleSelect}  name="locations" value={client.locations} 
className={errors.location?"form-control form-control-danger form-control-lg":""}
/>

                      {errors.location&&
                        <div className="col-form-label text-danger">{errors.location} </div>}

                        <i>Provide the location where you'd like the digital card to be delivered.
</i>
                    </div>



                    <div className="col-md-6 mb-1">
                      <label  className="form-label text-bold"> Preferred Delivery Address <span className='text-danger'>*</span></label>

                      <input type="text"  name="address" required autoComplete='off' role={'presentation'} value={client.address} onChange={handleChange} className={errors.address  ? 'form-control form-control-danger form-control-lg' : 'form-control form-control-lg'} /> 
                      {errors.address&&
                        <div className="col-form-label text-danger">{errors.address} </div>}

                        <i>Provide the address where you'd like the digital card to be delivered.
</i>
                    </div>


                    <div className="col-md-12 mb-1">
                      <label  className="form-label text-bold">Preferred Colour/Additional Info</label>

                      <input type="text"  name="comment"  autoComplete='off' role={'presentation'} value={client.comment} onChange={handleChange} className='form-control form-control-lg' />

<i>How do you want your card interface to look like? Pick <a href="https://www.color-hex.com/color-names.html" target='_blank'>COLOR CODE</a> </i>
                    </div>



<table className='table mt-3 table-bordered '>
  <tbody>
    <tr>
      <td><h5 className="card-title text-danger ">   Card Amount </h5></td>
      <td><h5 className="card-title text-danger ">  {currency+FormatNumber(client.card_price)} </h5></td>
    </tr>
    <tr>
      <td><h5 className="card-title text-danger ">   Delivery Amount </h5></td>
      <td><h5 className="card-title text-danger ">   {currency+FormatNumber(client.location_price)} </h5></td>
    </tr>
   {/*  <tr>
      <td><h5 className="card-title text-danger ">   Convenience Fee </h5></td>
      <td><h5 className="card-title text-danger ">   {currency+FormatNumber((0.015 * client.total)+100)} </h5></td>
    </tr> */}

    
    <tr>
      <td><h5 className="card-title text-primary ">   Total Amount </h5></td>
      <td><h5 className="card-title text-primary ">  {currency+FormatNumber(Number(client.total))} </h5></td>
    </tr>
  </tbody>

</table>
                  


        </div>

                    </form>:

<div className='border-2 border-primary border p-3 mt-3 mb-3'>


<p className='text-danger'>{errors.fullname}</p>
<p className='text-danger'>{errors.position}</p>
<p className='text-danger'>{errors.location}</p>
<p className='text-danger'>{errors.address}</p>




<a   onClick={() => {
                handlePaystackPayment(onSuccess, onClose)
            }} id='openmodal' >

</a>
<h3>Select Mode of Payment </h3>



<div className="accordion mb-3 " id={'accordionBank'} >
<div className="accordion-item ">
  <h2 className="accordion-header text-brand fw-bold" id={"headingBank"}>
    <button className="accordion-button text-brand fw-bold" type="button" data-bs-toggle="collapse"  data-bs-target={"#collapseBank"} aria-expanded="true" aria-controls={"collapseBank"}>
   Bank Payment
    </button>
  </h2>
  <div id={"collapseBank"} className="accordion-collapse collapse " aria-labelledby={"headingBank"} data-bs-parent={"#accordionBank"} >
    <div className="accordion-body">

<div className="row mb-3 mt-3">


  <div className="col-sm-12">

  <p dangerouslySetInnerHTML={{__html:card.bank_details}}></p>
 
  </div>

  <button type="button" onClick={(e)=>handleSubmit(e, 'closeModal')}   className="btn btn-success">Click Here To Submit Request </button>



</div>



    </div>
  </div>
</div>
</div>






{/* <div className="accordion mb-3 " id={'accordionDelivery'} >
<div className="accordion-item ">
  <h2 className="accordion-header text-brand fw-bold" id={"headingDelivery"}>
    <button className="accordion-button text-brand fw-bold" type="button" data-bs-toggle="collapse"  data-bs-target={"#collapseDelivery"} aria-expanded="true" aria-controls={"collapseDelivery"}>
   Pay After Delivery
    </button>
  </h2>
  <div id={"collapseDelivery"} className="accordion-collapse collapse " aria-labelledby={"headingDelivery"} data-bs-parent={"#accordionDelivery"} >
    <div className="accordion-body">

<div className="row mb-3 mt-3">


  <div className="col-sm-12">

  <p> This option allow you to make payment after receipt of your card </p>
 
  </div>

  <button type="button" onClick={(e)=>handleSubmit(e, 'closeModal')}   className="btn btn-success">Click Here To Submit Request </button>



</div>



    </div>
  </div>
</div>
</div>
 */}




<div className="accordion mb-3 " id={'accordionOnline'} >
<div className="accordion-item ">
  <h2 className="accordion-header text-brand fw-bold" id={"headingOnline"}>
    <button className="accordion-button text-brand fw-bold" type="button" data-bs-toggle="collapse"  data-bs-target={"#collapseOnline"} aria-expanded="true" aria-controls={"collapseOnline"}>
   Pay Online Now
    </button>
  </h2>
  <div id={"collapseOnline"} className="accordion-collapse collapse show " aria-labelledby={"headingOnline"} data-bs-parent={"#accordionOnline"} >
    <div className="accordion-body">

<div className="row mb-3 mt-3">


  <div className="col-sm-12">

  <p> Pay online with your debit or credit card</p>
 
  </div>

  
<button type="button" onClick={(e)=>handleSubmit(e, 'openmodal')}  className="btn btn-success">Click Here To Make Payment ({amount/100})</button> 

</div>



    </div>
  </div>
</div>
</div>

</div>
                    

          }

                    </div>
                    <div className="modal-footer">
                      

                      {activeItem ===1 ? <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" id='closeModal' >Close</button> : <button type="button" className='btn btn-secondary  ' onClick={handleOnClickBack} >Previous
                                    </button>}

                                    {activeItem === steps.length ?

                     '' :

                      <button type="button" onClick={handleOnClickNext}   className="btn btn-primary">Proceed </button>
                                    }
                    </div>
                  </div>
                </div>
              </div>


</div>
</div>

<Footer />
</>
  )
}

export default RequestCard