import { useEffect, useState, FormEvent, ChangeEvent } from 'react'
import Header from '../components/Header';
import Footer from '../components/Footer';

import axios from 'axios'
import { FrontUrl, ImagesUrl, ServerUrl, user_code,  config, configDownload, FormatNumber } from '../components/includes';
import QRCode from 'qrcode'
import { ErrorModal, LoadingModal, SuccessModal } from '../components/notify'

const VisitDetails= () => {

  const [content, setContent] = useState([]as any)

  const fetchContent = async()=>{
    let url = ServerUrl+'/visit/view/'+user_code
      await  axios.get(url,  config).then((result:any)=>{
        if(result.data.data&&result.data.data.length!==0){
            try{
        setContent(result.data.data)
    }catch(e){

    }
        }
        })
        .catch((error)=>{
       
        })
      }

      useEffect(()=>{
        fetchContent()
       }, []) 

  return (
<>
<Header />




<div className="page-content mt-4">
<div className="container">
<h5>Profile Visits</h5>

<nav aria-label="breadcrumb">
  <ol className="breadcrumb">
    <li className="breadcrumb-item"><a href="/dashboard">Home</a></li>
    <li className="breadcrumb-item active" aria-current="page">Visit Details</li>
  </ol>
</nav>


  <div className="row mt-4">

  
{content&&content.map((item:any, id:number)=>
<div className=" col-sm-12 col-md-6 col-lg-6 col-xl-3" key={id}>
<div className="card custom-card">
    <div className="card-body user-card text-center">



    <div className="card-order ">

<label className="main-content-label mb-3 pt-1">
  {item.date_added}
</label>

<div className="d-flex justify-content-between">
  <div>
<h4 className="text-left card-item-icon card-icon">
  <span className="font-weight-bold text-success">{item.country}</span></h4>
<p className="mb-0  text-danger">({item.state})</p>
</div>

<h3 className="  " >{item.total}</h3>

</div>

            </div>


    </div>
</div>
</div>)}
                                </div>

</div>
</div>

<Footer />
</>
  )
}

export default VisitDetails