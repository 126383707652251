import { useEffect, useState } from 'react'
import Header from '../components/Header';
import Footer from '../components/Footer';

import axios from 'axios'
import { ServerUrl, user_code,  config } from '../components/includes';
import { LoadingModal, closeModal } from '../components/notify'
import { timeSince } from '../components/GlobalFunction';

const Notification = () => {
 

  const [errors, setErrors] = useState({
    fullname:'',
    location:'',
    address:'',
    position:'',
    successMessage:'',
    errorMessage:'',
    image:''
  });
  const [content, setContent] = useState([]as any)


  
  

  const handleMessage = async(code:string)=>{
    let url = ServerUrl+'/client/read_message/'+code
      await  axios.get(url,  config).then((result:any)=>{
        if(result.data.data&&result.data.data.length!==0){
          setContent(result.data.data)
        }
        })
        .catch((error)=>{
       
        })
      }

  const fetchContent = async()=>{
    LoadingModal()
    let url = ServerUrl+'/notifications/'+user_code
      await  axios.get(url,  config).then((result:any)=>{
        if(result.data.data&&result.data.data.length!==0){
          setContent(result.data.data)
        }
        })
        .catch((error)=>{
       
        }).finally(()=>{
          closeModal()
        })
      }


        

      useEffect(()=>{
        fetchContent()
       }, []) 

  return (
<>
<Header />




<div className="page-content mt-4">
<div className="container">
<h5>Notifications</h5>

<nav aria-label="breadcrumb">
  <ol className="breadcrumb">
  <li className="breadcrumb-item"><a href="/dashboard"><i className='fa fa-desktop'></i></a></li>
    <li className="breadcrumb-item active" aria-current="page">Notifications</li>
  </ol>
</nav>


<div className="row mt-4">
			



{errors.successMessage!==''?
				  <div className="alert alert-success alert-dismissible fade show" role="alert">
          {errors.successMessage}
            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
          </div>:''}

			 {errors.errorMessage!==''?
				  <div className="alert alert-danger alert-dismissible fade show" role="alert">
          {errors.errorMessage}
            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
          </div>:''}



<div className="alert alert-primary alert-dismissible fade show" role="alert">
Click on any of the message subject to reveal the message content
                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
              </div>





<div className="card">
            <div className="card-body">
              <h5 className="card-title">All Messages</h5>



              <ol className="list-group ">


                {content&&content.map((list:any, index:number)=>

<div className="accordion mb-3 " id={'accordion'+list.code} key={index}>
<div className="accordion-item ">
  <h2 className="accordion-header text-brand fw-bold" id={"heading"+list.code}>
    <button className="accordion-button text-brand fw-bold" type="button" data-bs-toggle="collapse" onClick={()=>handleMessage(list.code)} data-bs-target={"#collapse"+list.code} aria-expanded="true" aria-controls={"collapse"+list.code}>
   #{list.subject} - { timeSince(new Date(list.created_at))}
    </button>
  </h2>
  <div id={"collapse"+list.code} className="accordion-collapse collapse " aria-labelledby={"heading"+list.code} data-bs-parent={"#accordion"+list.code} >
    <div className="accordion-body">

<div className="row mb-3 mt-3">


  <div className="col-sm-12">

  <p dangerouslySetInnerHTML={{__html:list.html_body}}></p>
 
  </div>

</div>



    </div>
  </div>
</div>
</div>)}
              
              </ol>

            </div>
          </div>
	</div>



 







</div>
</div>

<Footer />
</>
  )
}

export default Notification